import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {autoRetry, failuresToToast} from "../service";
import {useTranslation} from "react-i18next";
import {useLogin} from "../common/CustomHooks";
import {confirm, toggleCheckbox} from "../common/Forms";
import ProjectStickyMenuComponent from "./ProjectStickyMenuComponent";
import {pushIfMissing} from "../common/ArrayUtils";
import {toLangOnly} from "../common/LangUtils";
import FindingCreateEditComponent from "./FindingCreateEditComponent";
import {multilines} from "../common/TextUtils";
import ApplicantCreateEditComponent from "./ApplicantCreateEditComponent";
import {toIsoFullDate} from "../common/DateUtils";
import DelayedInput from "../common/DelayedInput";
import SearchInspector from "../common/SearchInspector";
import OwnerCreateEditComponent from "./OwnerCreateEditComponent";
import PresentPersonCreateEditComponent from "./PresentPersonCreateEditComponent";
import DelayedStringList from "../common/DelayedStringList";
import SearchInternalListLocal from "../common/SearchInternalListLocal";
import CheckboxesInternalListLocal from "../common/CheckboxesInternalListLocal";
import RadioButtonInternalListLocal from "../common/RadioButtonInternalListLocal";
import AddressAutocomplete from "../common/AddressAutocomplete";
import ListSearchInternalListLocal from "../common/ListSearchInternalListLocal";
import MapSearchInternalListLocal from "../common/MapSearchInternalListLocal";
import RadioButtonYesNo from "../common/RadioButtonYesNo";
import FileUpload from "../common/FileUpload";
import ImageViewer from "../common/ImageViewer";
import ProjectWarningsBadge from "./ProjectWarningsBadge";
import ProjectWarnings from "./ProjectWarnings";
import PhotoSelector from "../common/PhotoSelector";
import ListSearchInternalListWithPrecisionLocal from "../common/ListSearchInternalListWithPrecisionLocal";
import CheckboxBoolean from "../common/CheckboxBoolean";
import AutoFormField from "../common/AutoFormField";
import {traverseFieldDefinition, traverseObject} from "../common/ObjectUtils";
import ElectricDistributionPanel from "./ElectricDistributionPanel";
import './ProjectViewPage.css'
import {toast} from "react-toastify";

/**
 * The project view page.
 *
 * props.googleMapsApiReady: True if the Google Maps API is ready
 * props.userEntitlements: The users entitlements
 * props.needsLogin(hash): The action to do when the user is not logged in and needs to log in
 */
function ProjectViewPage(props) {

    const {id} = useParams()

    const {t, i18n} = useTranslation()

    const [itemResponse, setItemResponse] = useState({})
    const [item, setItem] = useState(null)
    const [automaticForm, setAutomaticForm] = useState({})
    const [buildingAddress, setBuildingAddress] = useState({
        full: '',
        placeId: null,
    })

    const [listByType, setListByType] = useState(null)
    const [internalListById, setInternalListById] = useState({})
    const [showCreateApplicant, setShowCreateApplicant] = useState(false)
    const [editApplicantEditByIds, setEditApplicantEditByIds] = useState({})

    const [showCreateOwner, setShowCreateOwner] = useState(false)
    const [editOwnerEditByIds, setEditOwnerEditByIds] = useState({})

    const [showCreatePresentPerson, setShowCreatePresentPerson] = useState(false)
    const [editPresentPersonEditByIds, setEditPresentPersonEditByIds] = useState({})

    const [editFindingEditByIds, setEditFindingEditByIds] = useState({})
    const [allPhotosCreateFindingForm, setAllPhotosCreateFindingForm] = useState(null)

    const [viewImage, setViewImage] = useState(null)
    const [forceRefresh, setForceRefresh] = useState({})

    const [showCreateFindingByKey, setShowCreateFindingByKey] = useState({})

    useLogin(props.userEntitlements, props.needsLogin, '/project/' + id)

    // Initial loading of the project
    useEffect(() => {
        if (id && props.userEntitlements && props.userEntitlements.isLoggedIn) {
            refreshAutomaticForm()
            refreshProject()
        }
    }, [id, props.userEntitlements])

    // Get the sections and subsections details
    useEffect(() => {
        if (id && props.userEntitlements && props.userEntitlements.isLoggedIn) {
            failuresToToast(t('common.details'), () => window.service.internalListMapAll(), false).then(response => {
                console.log('internal lists response', response)
                setListByType(response.data.listByType)
                setInternalListById(toItemById(response.data.listByType))
            })
        }
    }, [id, props.userEntitlements])

    const ready = item !== null && listByType !== null
    if (!ready) {
        return <>LOADING</>
    }

    const isFinalized = item.inspectionReportFinalizedDate != null

    function toItemById(listByType) {
        const itemById = {}
        if (listByType) {
            for (const [type, items] of Object.entries(listByType)) {
                if (items) {
                    items.forEach(i => itemById[i.id] = i)
                }
            }
        }
        return itemById
    }

    // Add building part type first feature
    const residentialBuildingPartTypeIdsFeatures = []
    if (item && item.residentialBuildingPartTypeIds && item.residentialBuildingPartTypeIds.length > 0) {
        // Go through all the building part types and keep the all the features
        const buildingPartTypeIds = item.residentialBuildingPartTypeIds
        buildingPartTypeIds.forEach(id => {
            const features = internalListById[id]?.features || []
            features.forEach(feature => pushIfMissing(residentialBuildingPartTypeIdsFeatures, feature))
        })
    }

    function refreshAutomaticForm() {
        failuresToToast(t('project.context'), () => window.service.projectAutomaticForm(), false).then(response => {
            setAutomaticForm(response.data.item.fields)
        })
    }

    function refreshProject() {
        failuresToToast(t('project.context'), () => window.service.projectGet(id), false).then(response => {
            setItemResponse(response.data)
            setProject(response.data.item)
        })
    }

    function findingUpdated(findingId, project) {
        toggleCheckbox(editFindingEditByIds, setEditFindingEditByIds, findingId)
        setProject(project)
    }

    function deleteFinding(findingId) {
        confirm(t('project.finding.deleteConfirm'), () =>
            failuresToToast(t('project.finding.deleted'), () => window.service.projectFindingDelete(id, findingId)).then(response => {
                setProject(response.data.item)
            })
        )
    }

    function deletePhoto(photoId) {
        confirm(t('photo.deleteConfirm'), () =>
            failuresToToast(t('photo.deleted'), () => window.service.projectPhotoDelete(id, photoId)).then(response => {
                setProject(response.data.item)
            })
        )
    }

    function setProject(project) {

        // Ensure default values
        if (project.specialConditions === null || project.specialConditions === undefined) {
            project.specialConditions = []
        }

        if (project.buildingAddress === null || project.buildingAddress === undefined) {
            project.buildingAddress = {
                full: '',
                placeId: null,
            }
        }
        setBuildingAddress({...project.buildingAddress})

        if (project.dependencyDescriptionIds === null || project.dependencyDescriptionIds === undefined) {
            project.dependencyDescriptionIds = []
        }

        if (project.templateSectionIds === null || project.templateSectionIds === undefined) {
            project.templateSectionIds = []
        }

        // Process the findings
        const findings = project.findings ? project.findings : []
        const sectionIds = []
        project.templateSectionIds.forEach(sectionId => pushIfMissing(sectionIds, sectionId))
        const subSectionsBySectionId = {}
        const precisionsBySubSectionId = {}
        const findingsBySubSectionAndPrecisionId = {}
        findings.forEach(finding => {

            // Add section
            const sectionId = finding.sectionId
            const subSectionId = finding.subSectionId
            const precisionId = finding.precisionId
            pushIfMissing(sectionIds, sectionId)

            // Add subsection in section
            if (!subSectionsBySectionId[sectionId]) {
                subSectionsBySectionId[sectionId] = []
            }
            pushIfMissing(subSectionsBySectionId[sectionId], subSectionId)

            // Add precision in subsection
            if (!precisionsBySubSectionId[subSectionId]) {
                precisionsBySubSectionId[subSectionId] = []
            }
            pushIfMissing(precisionsBySubSectionId[subSectionId], precisionId)

            // Add finding by subsection
            if (!findingsBySubSectionAndPrecisionId[subSectionId + precisionId]) {
                findingsBySubSectionAndPrecisionId[subSectionId + precisionId] = []
            }
            findingsBySubSectionAndPrecisionId[subSectionId + precisionId].push(finding)
        })
        project.sectionIds = sectionIds
        project.subSectionsBySectionId = subSectionsBySectionId
        project.precisionsBySubSectionId = precisionsBySubSectionId
        project.findingsBySubSectionAndPrecisionId = findingsBySubSectionAndPrecisionId

        setItem(project)
    }

    function applicantCreated(project) {
        setShowCreateApplicant(false)
        setProject(project)
    }

    function applicantUpdated(applicantId, project) {
        toggleCheckbox(editApplicantEditByIds, setEditApplicantEditByIds, applicantId)
        setProject(project)
    }

    function deleteApplicant(applicantId) {
        confirm(t('project.applicant.deleteConfirm'), () =>
            failuresToToast(t('project.applicant.deleted'), () => window.service.projectApplicantDelete(id, applicantId)).then(response => {
                setProject(response.data.item)
            })
        )
    }

    function ownerCreated(project) {
        setShowCreateOwner(false)
        setProject(project)
    }

    function ownerUpdated(ownerId, project) {
        toggleCheckbox(editOwnerEditByIds, setEditOwnerEditByIds, ownerId)
        setProject(project)
    }

    function deleteOwner(ownerId) {
        confirm(t('project.owner.deleteConfirm'), () =>
            failuresToToast(t('project.owner.deleted'), () => window.service.projectOwnerDelete(id, ownerId)).then(response => {
                setProject(response.data.item)
            })
        )
    }

    function presentPersonCreated(project) {
        setShowCreatePresentPerson(false)
        setProject(project)
    }

    function presentPersonUpdated(presentPersonId, project) {
        toggleCheckbox(editPresentPersonEditByIds, setEditPresentPersonEditByIds, presentPersonId)
        setProject(project)
    }

    function deletePresentPerson(presentPersonId) {
        confirm(t('project.presentPerson.deleteConfirm'), () =>
            failuresToToast(t('project.presentPerson.deleted'), () => window.service.projectPresentPersonDelete(id, presentPersonId)).then(response => {
                setProject(response.data.item)
            })
        )
    }

    function updateAddressField(fieldName, currentAddress, setCurrentAddress, newValue) {
        const oldPlaceId = currentAddress.placeId
        let newPlaceId = newValue.placeId
        let newFull = newValue.full

        if (newPlaceId === null) {
            if (newFull !== '') {
                newPlaceId = oldPlaceId
            }
        }

        setCurrentAddress(prevAddress => ({...prevAddress, full: newFull}))

        if (oldPlaceId !== newPlaceId) {
            if (newPlaceId == null) {
                newValue = null
            }
            failuresToToast(t('project.context'), () => window.service.projectUpdate(id, fieldName, newValue), false).then(response => {
                setProject(response.data.item)
            })
        }
    }

    function updateField(fieldName, newValue) {

        let itemField = traverseObject(fieldName, item)

        if (newValue && newValue !== itemField) {
            failuresToToast(t('project.context'), () => window.service.projectUpdate(id, fieldName, newValue), false).then(response => {
                setProject(response.data.item)
            })
        }
    }

    function updateAutomaticOnAddToMany(fieldDefinition, fieldFullName) {
        failuresToToast(t('project.context'), () => window.service.projectUpdateAutomaticAddToMany(id, fieldFullName), false).then(response => {
            setProject(response.data.item)
        })
    }

    function updateAutomaticOnRemoveFromMany(fieldDefinition, fieldFullName, index) {
        failuresToToast(t('project.context'), () => window.service.projectUpdateAutomaticRemoveFromMany(id, fieldFullName, index), false).then(response => {
            setProject(response.data.item)
        })
    }

    function updateAutomaticOnChangeSelectedItem(fieldDefinition, fieldFullName, newValue) {

        let itemField = traverseObject(fieldFullName, item)

        if (newValue && newValue !== itemField) {
            if (fieldDefinition.internalListWithPrecision) {
                failuresToToast(t('project.context'), () => window.service.projectUpdateAutomaticIdAndPrecisionId(id, fieldFullName, newValue), false).then(response => {
                    setProject(response.data.item)
                })
            } else {
                // if newValue is a list, send comma separated
                const newValueToSend = Array.isArray(newValue) ? newValue.join(',') : newValue
                failuresToToast(t('project.context'), () => window.service.projectUpdateAutomaticText(id, fieldFullName, newValueToSend), false).then(response => {
                    setProject(response.data.item)
                })
            }
        }
    }

    function updateAutomaticOnChangeSelectedItemAcceptEmpty(fieldDefinition, fieldFullName, newValue) {

        let itemField = traverseObject(fieldFullName, item)

        if (newValue !== itemField) {
            if (fieldDefinition.internalListWithPrecision) {
                failuresToToast(t('project.context'), () => window.service.projectUpdateAutomaticIdAndPrecisionId(id, fieldFullName, newValue), false).then(response => {
                    setProject(response.data.item)
                })
            } else {
                // if newValue is a list, send comma separated
                const newValueToSend = Array.isArray(newValue) ? newValue.join(',') : newValue
                failuresToToast(t('project.context'), () => window.service.projectUpdateAutomaticText(id, fieldFullName, newValueToSend), false).then(response => {
                    setProject(response.data.item)
                })
            }
        }
    }

    function updateFieldEvenWhenNull(fieldName, newValue) {
        if (newValue !== item[fieldName]) {
            failuresToToast(t('project.context'), () => window.service.projectUpdate(id, fieldName, newValue), false).then(response => {
                setProject(response.data.item)
            })
        }
    }

    function updateInternalListField(fieldName, newItem, canBeNull = false) {

        let itemField = traverseObject(fieldName, item)

        if (newItem == null) {
            if (canBeNull) {
                if (itemField) {
                    failuresToToast(t('project.context'), () => window.service.projectUpdate(id, fieldName, null), false).then(response => {
                        setProject(response.data.item)
                    })
                }
            }
        } else {
            if (newItem && newItem.id !== itemField) {
                failuresToToast(t('project.context'), () => window.service.projectUpdate(id, fieldName, newItem.id), false).then(response => {
                    setProject(response.data.item)
                })
            }
        }
    }

    function updateInternalListFieldList(fieldName, newItems) {

        // Traverse item object with the fieldName with separator '.'
        const fieldNames = fieldName.split('.')
        let itemField = item
        for (let i = 0; i < fieldNames.length; i++) {
            itemField = itemField[fieldNames[i]]
        }
        if (itemField === undefined) {
            itemField = []
        }

        const ids = newItems.map(item => item?.id)
        const nonNullIds = ids.filter(id => id !== null && id !== undefined && id !== '')
        if (nonNullIds.join() !== itemField.join()) {
            failuresToToast(t('project.context'), () => window.service.projectUpdate(id, fieldName, nonNullIds), false).then(response => {
                setProject(response.data.item)
            })
        } else {
            const newItem = {...item}
            let newItemField = newItem
            for (let i = 0; i < fieldNames.length - 1; i++) {
                newItemField = newItemField[fieldNames[i]]
            }
            newItemField[fieldNames[fieldNames.length - 1]] = ids
            setItem(newItem)
        }
    }

    function updateInternalListIdAndPrecisionFieldList(fieldName, newEntries) {

        // Traverse item object with the fieldName with separator '.'
        const fieldNames = fieldName.split('.')
        let itemField = item
        for (let i = 0; i < fieldNames.length; i++) {
            itemField = itemField[fieldNames[i]]
        }
        if (itemField === undefined) {
            itemField = []
        }

        const nonEmptyNewEntries = newEntries.filter(entry => (entry.id !== null && entry.id !== undefined && entry.id !== '') || (entry.precisionId !== null && entry.precisionId !== undefined && entry.precisionId !== ''))
        if (JSON.stringify(nonEmptyNewEntries) !== JSON.stringify(itemField)) {
            failuresToToast(t('project.context'), () => window.service.projectUpdate(id, fieldName, newEntries), false).then(response => {
                setProject(response.data.item)
            })
        } else {
            const newItem = {...item}
            let newItemField = newItem
            for (let i = 0; i < fieldNames.length - 1; i++) {
                newItemField = newItemField[fieldNames[i]]
            }
            newItemField[fieldNames[fieldNames.length - 1]] = newEntries
            setItem(newItem)
        }
    }

    function updateInternalListIdAndPrecisionField(fieldName, newEntry) {

        // Traverse item object with the fieldName with separator '.'
        const fieldNames = fieldName.split('.')
        let itemField = item
        for (let i = 0; i < fieldNames.length; i++) {
            itemField = itemField[fieldNames[i]]
        }
        if (itemField === undefined) {
            itemField = {
                id: null,
                precisionId: null,
            }
        }

        if (newEntry.id !== itemField.id || newEntry.precisionId !== itemField.precisionId) {
            failuresToToast(t('project.context'), () => window.service.projectUpdate(id, fieldName, newEntry), false).then(response => {
                setProject(response.data.item)
            })
        } else {
            const newItem = {...item}
            let newItemField = newItem
            for (let i = 0; i < fieldNames.length - 1; i++) {
                newItemField = newItemField[fieldNames[i]]
            }
            newItemField[fieldNames[fieldNames.length - 1]] = newEntry
            setItem(newItem)
        }
    }

    function toggleVocationCommercialUnitsCount() {
        const newValue = item.vocationCommercialUnitsCount ? 0 : 1
        failuresToToast(t('project.context'), () => window.service.projectUpdate(id, 'vocationCommercialUnitsCount', newValue), false).then(response => {
            setProject(response.data.item)
        })
    }

    function toggleVocationHousingUnitsCount() {
        const newValue = item.vocationHousingUnitsCount ? 0 : 1
        failuresToToast(t('project.context'), () => window.service.projectUpdate(id, 'vocationHousingUnitsCount', newValue), false).then(response => {
            setProject(response.data.item)
        })
    }

    function projectDocumentAdd(document, file) {
        return failuresToToast(t('project.context'), () => window.service.projectDocumentAdd(id, document.displayId, file), false).then(response => {
            if (response.data.item) {
                setProject(response.data.item)
            }
        })
    }

    function projectDocumentDelete(documentDisplayId, documentId) {
        failuresToToast(t('project.context'), () => window.service.projectDocumentDelete(id, documentDisplayId, documentId), false).then(response => {
            setProject(response.data.item)
        })
    }

    function projectDocumentUpdateNoDocumentJustification(document, newValue) {
        failuresToToast(t('project.context'), () => window.service.projectDocumentUpdateNoDocumentJustification(id, document.displayId, newValue), false).then(response => {
            setProject(response.data.item)
        })
    }

    function projectPhotoAdd(file) {
        return failuresToToast(t('project.context'), () => window.service.projectPhotoAdd(id, file), false).then(response => {
            if (response.data.item) {
                setProject(response.data.item)
            }
        })
    }

    function toDocumentName(namepart, documentId) {
        let parts = documentId.split(".")
        let ext = parts.pop()
        let fullName = namepart + '.' + ext
        fullName = fullName.replace(/’/g, "'");
        return encodeURIComponent(fullName)
    }

    function scrollToTopSections() {
        // Get the height of the menu
        const projectMenuHeight = document.getElementById('projectMenu').offsetHeight

        // Get the top position of the element with the id
        const top = document.getElementById('sections').offsetTop

        // Scroll
        window.scrollTo(0, top - projectMenuHeight)
    }

    function scrollToSection(sectionId) {

        // Collapse all sections
        const sections = document.getElementsByClassName('section')
        for (let i = 0; i < sections.length; i++) {
            sections[i].classList.remove('show')
        }

        // Uncollapse if collapsed
        const uncollapseId = 'section-' + sectionId
        const uncollapseElement = document.getElementById(uncollapseId)
        if (uncollapseElement) {
            uncollapseElement.classList.add('show')
        }

        // Get the height of the menu
        const projectMenuHeight = document.getElementById('projectMenu').offsetHeight

        // Get the top position of the element with the id
        const h4Element = document.getElementById('h4-' + uncollapseId)
        const top = h4Element.offsetTop

        // Scroll
        window.scrollTo(0, top - projectMenuHeight)
    }

    function createFindingWithSeletectedPhotos(photoIds) {
        setAllPhotosCreateFindingForm({
            photoIds: photoIds,
        })
    }

    function createdFindingWithSeletectedPhotos(project) {
        setProject(project)
        setAllPhotosCreateFindingForm(null)
    }

    function changeSelectedPhoto(photoIdIdx, finding) {
        if (photoIdIdx < 0) {
            photoIdIdx = finding.photoIds.length - 1
        }
        if (photoIdIdx >= finding.photoIds.length) {
            photoIdIdx = 0
        }

        const photoId = finding.photoIds[photoIdIdx]
        setViewImage({
            photoId: photoId,
            findingId: finding.id,
            edit: true,
            previous: () => changeSelectedPhoto(photoIdIdx - 1, finding),
            next: () => changeSelectedPhoto(photoIdIdx + 1, finding),
            forceRefresh: () => setForceRefresh(prev => ({
                ...prev,
                [photoId]: new Date().getTime()
            })),
        })
    }

    function sendReport() {
        autoRetry(t('project.sendReport'), () => window.service.projectSendReport(id), 5).then(response => {
            if (response.data.success) {
                setItem(response.data.item)
                toast.success(t('project.sendReportSuccess'))
            } else {
                if (response.data.error) {
                    const error = response.data.error
                    toast.error(`${error.timestamp} - ${error.uniqueId} - ${error.message}`)
                }
            }
        })
    }

    if (isFinalized) {
        return (
            <div className="row">
                <div className="col">
                    <h2>{t('project.title')}</h2>

                    <h3 id="details"><i className="bi bi-info-square menuIcon"/>{t('project.details')}</h3>

                    <div id="detailsSection">
                        <table className="table">
                            <tbody>
                            <tr>
                                <th>{t('project.id')}</th>
                                <td>{item.id}</td>
                            </tr>
                            <tr>
                                <th>{t('project.creationDate')}</th>
                                <td>{toIsoFullDate(item.creationDate)}</td>
                            </tr>
                            <tr>
                                <th>{t('project.businessName')}</th>
                                <td>{item.business?.name}</td>
                            </tr>
                            <tr>
                                <th>{t('project.inspectorEmail')}</th>
                                <td>{item.inspectorName}</td>
                            </tr>
                            <tr>
                                <th>{t('project.reportId')}</th>
                                <td>{item.reportIdText}</td>
                            </tr>
                            <tr>
                                <th>{t('project.inspectionReportFinalizedDate')}</th>
                                <td>{toIsoFullDate(item.inspectionReportFinalizedDate)}</td>
                            </tr>
                            <tr>
                                <th>{t('project.inspectionReportSentDate')}</th>
                                <td>{toIsoFullDate(item.inspectionReportSentDate)}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <h3 id="applicants"><i className="bi bi-person-exclamation menuIcon"/>
                        {t('project.applicants')}
                        <ProjectWarningsBadge project={item} prefix="applicants"/>
                    </h3>

                    <div id="applicantsSection">

                        {item.applicants?.map(applicant => (<div key={applicant.id}>

                                <div className="roundCard">

                                    <table className="table">
                                        <tbody>
                                        <tr>
                                            <th>{t('project.applicant.typeId')}</th>
                                            <td>
                                                {internalListById[applicant.typeId]?.value[toLangOnly(i18n.language)]}
                                            </td>
                                        </tr>
                                        {internalListById[applicant.typeId]?.features.includes('individual') && <>
                                            <tr>
                                                <th>{t('project.applicant.firstName')}</th>
                                                <td>
                                                    {applicant.firstName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.applicant.lastName')}</th>
                                                <td>
                                                    {applicant.lastName}
                                                </td>
                                            </tr>
                                            {applicant.representativeFirstName &&
                                                <tr>
                                                    <th>{t('project.applicant.representativeFirstName')}</th>
                                                    <td>
                                                        {applicant.representativeFirstName}
                                                    </td>
                                                </tr>
                                            }
                                            {applicant.representativeLastName &&
                                                <tr>
                                                    <th>{t('project.applicant.representativeLastName')}</th>
                                                    <td>
                                                        {applicant.representativeLastName}
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                        }
                                        {internalListById[applicant.typeId]?.features.includes('corporation') && <>
                                            <tr>
                                                <th>{t('project.applicant.corporationName')}</th>
                                                <td>
                                                    {applicant.corporationName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.applicant.representativeFirstName')}</th>
                                                <td>
                                                    {applicant.representativeFirstName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.applicant.representativeLastName')}</th>
                                                <td>
                                                    {applicant.representativeLastName}
                                                </td>
                                            </tr>
                                        </>
                                        }
                                        <tr>
                                            <th>{t('project.applicant.currentAddress')}</th>
                                            <td>
                                                {applicant.currentAddress?.full}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('project.applicant.phoneNumber')}</th>
                                            <td>
                                                {applicant.phoneNumber}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('project.applicant.email')}</th>
                                            <td>
                                                {applicant.email}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="text-center">
                                                {applicant.presentDuringInspection ? t('project.applicant.presentDuringInspection') : t('project.applicant.absentDuringInspection')}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>

                    <h3>{t('common.actions')}</h3>

                    <div id="detailsSection">
                        <table className="table">
                            <tbody>
                            <tr>
                                <th>{t('project.downloadReport')}</th>
                                <td>
                                    <a className="btn btn-success float-end"
                                       href={'/project/' + item.id + '/archive/inspection.pdf'}
                                       target={'_blank'}
                                       title={t('project.downloadReport')}
                                    >
                                        <i className="bi bi-file-earmark-pdf-fill"></i>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th>{t('project.sendReport')}</th>
                                <td>
                                    <button className="btn btn-success float-end"
                                            onClick={sendReport}
                                            title={t('project.sendReport')}
                                    >
                                        <i className="bi bi-envelope-at"></i>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        )
    }


    return (
        <div className="row">
            <div className="col">

                <ImageViewer
                    projectId={id}
                    photoId={viewImage?.photoId}
                    findingId={viewImage?.findingId}
                    edit={viewImage?.edit}
                    onClose={changed => {
                        setViewImage(null)
                        // Refresh the image if changed
                        if (changed && viewImage.forceRefresh) {
                            viewImage.forceRefresh()
                        }
                    }}
                    onPrevious={viewImage?.previous}
                    onNext={viewImage?.next}
                />

                <h2>{t('project.title')}</h2>

                <ProjectStickyMenuComponent
                    listByType={listByType}
                    internalListById={internalListById}
                    project={item}
                    updated={project => setProject(project)}
                />

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#detailsSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="details"><i className="bi bi-info-square menuIcon"/>{t('project.details')}
                    <ProjectWarningsBadge project={item} prefix="details."/>
                </h3>

                <div id="detailsSection" className="collapse">
                    <table className="table">
                        <tbody>
                        <tr>
                            <th>{t('project.id')}</th>
                            <td>{item.id}</td>
                        </tr>
                        <tr>
                            <th>{t('project.creationDate')}</th>
                            <td>
                                {toIsoFullDate(item.creationDate)}
                                <ProjectWarnings project={item} fieldName="details.creationDate"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.businessName')}</th>
                            <td>
                                {item.business?.name}
                                <ProjectWarnings project={item} fieldName="details.businessId"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.inspectorEmail')}</th>
                            <td>
                                <SearchInspector
                                    name="inspectorEmail"
                                    businessId={item.businessId}
                                    outsideChangedSelectedId={item.inspectorEmail}
                                    selectFirstInitially={false}
                                    onChangeSelectedItem={newValue => updateField('inspectorEmail', newValue?.email)}
                                />
                                <ProjectWarnings project={item} fieldName="details.inspectorEmail"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.inspectionTypeId')}</th>
                            <td>
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="inspectionTypeId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.inspectionDay')}</th>
                            <td>
                                <DelayedInput
                                    type="date" className="form-control"
                                    name="inspectionDay"
                                    initialValue={item.inspectionDay}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateField('inspectionDay', newValue)}
                                />
                                <ProjectWarnings project={item} fieldName="details.inspectionDay"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.inspectionStartTime')}</th>
                            <td>
                                <DelayedInput
                                    type="time" className="form-control"
                                    name="inspectionStartTime"
                                    initialValue={item.inspectionStartTime}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateField('inspectionStartTime', newValue)}
                                />
                                <ProjectWarnings project={item} fieldName="details.inspectionStartTime"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.inspectionEndTime')}</th>
                            <td>
                                <DelayedInput
                                    type="time" className="form-control"
                                    name="inspectionEndTime"
                                    initialValue={item.inspectionEndTime}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateField('inspectionEndTime', newValue)}
                                />
                                <ProjectWarnings project={item} fieldName="details.inspectionEndTime"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.inspectionDuration')}</th>
                            <td>{item.inspectionDuration}</td>
                        </tr>
                        <tr>
                            <th>{t('project.serviceAgreementSigningDay')}</th>
                            <td>
                                <DelayedInput
                                    type="date" className="form-control"
                                    name="serviceAgreementSigningDay"
                                    initialValue={item.serviceAgreementSigningDay}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateField('serviceAgreementSigningDay', newValue)}
                                />
                                <ProjectWarnings project={item} fieldName="details.serviceAgreementSigningDay"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.reportId')}</th>
                            <td>{item.reportIdText}</td>
                        </tr>
                        <tr>
                            <th>{t('project.inspectionReportFinalizedDate')}</th>
                            <td>{toIsoFullDate(item.inspectionReportFinalizedDate)}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#applicantsSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="applicants"><i className="bi bi-person-exclamation menuIcon"/>
                    {t('project.applicants')}
                    <ProjectWarningsBadge project={item} prefix="applicants"/>
                </h3>

                <div id="applicantsSection" className="collapse">
                    <button className="btn btn-success float-end"
                            onClick={() => setShowCreateApplicant(true)}
                    >+
                    </button>

                    <div className="clearfix"></div>

                    {showCreateApplicant && <div>
                        <ApplicantCreateEditComponent
                            googleMapsApiReady={props.googleMapsApiReady}
                            project={item}
                            updated={applicantCreated}
                            cancel={() => setShowCreateApplicant(false)}
                        />
                    </div>
                    }

                    <ProjectWarnings project={item} fieldName={`applicants`}/>

                    {item.applicants?.map(applicant => (<div key={applicant.id}>
                            {editApplicantEditByIds[applicant.id] &&
                                <ApplicantCreateEditComponent
                                    googleMapsApiReady={props.googleMapsApiReady}
                                    project={item}
                                    initialApplicant={applicant}
                                    updated={project => applicantUpdated(applicant.id, project)}
                                    cancel={() => toggleCheckbox(editApplicantEditByIds, setEditApplicantEditByIds, applicant.id)}
                                />
                            }

                            {!editApplicantEditByIds[applicant.id] &&
                                <div className="roundCard">

                                    <button className="btn btn-danger float-end" type="button"
                                            onClick={() => deleteApplicant(applicant.id)}>
                                        X
                                    </button>
                                    <button className="btn btn-primary float-end" type="button"
                                            onClick={() => toggleCheckbox(editApplicantEditByIds, setEditApplicantEditByIds, applicant.id)}>
                                        <i className="bi bi-pencil-square"></i>
                                    </button>

                                    <table className="table">
                                        <tbody>
                                        <tr>
                                            <th>{t('project.applicant.typeId')}</th>
                                            <td>
                                                {internalListById[applicant.typeId]?.value[toLangOnly(i18n.language)]}
                                                <ProjectWarnings project={item}
                                                                 fieldName={`applicants.${applicant.id}.typeId`}/>
                                            </td>
                                        </tr>
                                        {internalListById[applicant.typeId]?.features.includes('individual') && <>
                                            <tr>
                                                <th>{t('project.applicant.firstName')}</th>
                                                <td>
                                                    {applicant.firstName}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`applicants.${applicant.id}.firstName`}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.applicant.lastName')}</th>
                                                <td>
                                                    {applicant.lastName}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`applicants.${applicant.id}.lastName`}/>
                                                </td>
                                            </tr>
                                            {applicant.representativeFirstName &&
                                                <tr>
                                                    <th>{t('project.applicant.representativeFirstName')}</th>
                                                    <td>
                                                        {applicant.representativeFirstName}
                                                        <ProjectWarnings project={item}
                                                                         fieldName={`applicants.${applicant.id}.representativeFirstName`}/>
                                                    </td>
                                                </tr>
                                            }
                                            {applicant.representativeLastName &&
                                                <tr>
                                                    <th>{t('project.applicant.representativeLastName')}</th>
                                                    <td>
                                                        {applicant.representativeLastName}
                                                        <ProjectWarnings project={item}
                                                                         fieldName={`applicants.${applicant.id}.representativeLastName`}/>
                                                    </td>
                                                </tr>
                                            }
                                        </>
                                        }
                                        {internalListById[applicant.typeId]?.features.includes('corporation') && <>
                                            <tr>
                                                <th>{t('project.applicant.corporationName')}</th>
                                                <td>
                                                    {applicant.corporationName}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`applicants.${applicant.id}.corporationName`}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.applicant.representativeFirstName')}</th>
                                                <td>
                                                    {applicant.representativeFirstName}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`applicants.${applicant.id}.representativeFirstName`}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.applicant.representativeLastName')}</th>
                                                <td>
                                                    {applicant.representativeLastName}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`applicants.${applicant.id}.representativeLastName`}/>
                                                </td>
                                            </tr>
                                        </>
                                        }
                                        <tr>
                                            <th>{t('project.applicant.currentAddress')}</th>
                                            <td>
                                                {applicant.currentAddress?.full}
                                                <ProjectWarnings project={item}
                                                                 fieldName={`applicants.${applicant.id}.currentAddress`}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('project.applicant.phoneNumber')}</th>
                                            <td>
                                                {applicant.phoneNumber}
                                                <ProjectWarnings project={item}
                                                                 fieldName={`applicants.${applicant.id}.phoneNumber`}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('project.applicant.email')}</th>
                                            <td>
                                                {applicant.email}
                                                <ProjectWarnings project={item}
                                                                 fieldName={`applicants.${applicant.id}.email`}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="text-center">
                                                {applicant.presentDuringInspection ? t('project.applicant.presentDuringInspection') : t('project.applicant.absentDuringInspection')}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    ))}
                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#purposeOfTransactionSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="purposeOfTransaction"><i className="bi bi-receipt menuIcon"/>
                    {t('project.purposeOfTransaction')}
                    <ProjectWarningsBadge project={item} prefix="purposeOfTransaction."/>
                </h3>

                <div id="purposeOfTransactionSection" className="collapse">
                    <ProjectWarnings project={item} fieldName="purposeOfTransaction.transactionPurposeId"/>
                    <RadioButtonInternalListLocal
                        listByType={listByType}
                        name="transactionPurposeId"
                        type="PROJECT_TRANSACTION_PURPOSE"
                        selectedId={item.transactionPurposeId}
                        onChangeSelectedItem={(newItem) => updateInternalListField('transactionPurposeId', newItem)}
                    />
                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#residentialBuildingDescriptionSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="residentialBuildingDescription"><i className="bi bi-house-gear menuIcon"/>
                    {t('project.residentialBuildingDescription')}
                    <ProjectWarningsBadge project={item} prefix="residentialBuildingDescription."/>
                </h3>

                <div id="residentialBuildingDescriptionSection" className="collapse">
                    <table className="table">
                        <tbody>
                        <tr>
                            <th>{t('project.buildingCategoryId')}</th>
                            <td>
                                <SearchInternalListLocal
                                    listByType={listByType}
                                    name="buildingCategoryId"
                                    type="PROJECT_BUILDING_CATEGORY"
                                    selectedId={item.buildingCategoryId}
                                    onChangeSelectedItem={(newItem) => updateInternalListField('buildingCategoryId', newItem)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.buildingCategoryId"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingAddress')}</th>
                            <td>
                                {props.googleMapsApiReady && <AddressAutocomplete
                                    address={buildingAddress}
                                    onChange={newValue => updateAddressField('buildingAddress', buildingAddress, setBuildingAddress, newValue)}
                                />
                                }
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.buildingAddress"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.administrativeRegionId')}</th>
                            <td>
                                <SearchInternalListLocal
                                    listByType={listByType}
                                    name="administrativeRegionId"
                                    type="PROJECT_ADMINISTRATIVE_REGION"
                                    selectedId={item.administrativeRegionId}
                                    onChangeSelectedItem={(newItem) => updateInternalListField('administrativeRegionId', newItem)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.administrativeRegionId"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.constructionYear')}</th>
                            <td>
                                <DelayedInput
                                    type="number" className="form-control"
                                    id="constructionYear"
                                    name="constructionYear"
                                    min={1800} max={2050}
                                    initialValue={item.constructionYear}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateField('constructionYear', newValue)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.constructionYear"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingKindId')}</th>
                            <td>
                                <SearchInternalListLocal
                                    listByType={listByType}
                                    name="buildingKindId"
                                    type="PROJECT_BUILDING_KIND"
                                    selectedId={item.buildingKindId}
                                    onChangeSelectedItem={(newItem) => updateInternalListField('buildingKindId', newItem)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.buildingKindId"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingPhysicalLinkId')}</th>
                            <td>
                                <SearchInternalListLocal
                                    listByType={listByType}
                                    name="buildingPhysicalLinkId"
                                    type="PROJECT_BUILDING_PHYSICAL_LINK"
                                    selectedId={item.buildingPhysicalLinkId}
                                    onChangeSelectedItem={(newItem) => updateInternalListField('buildingPhysicalLinkId', newItem)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.buildingPhysicalLinkId"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingFloorAreaInSquareMeters')}</th>
                            <td>
                                <DelayedInput
                                    type="number" className="form-control"
                                    id="buildingFloorAreaInSquareMeters"
                                    name="buildingFloorAreaInSquareMeters"
                                    min={0}
                                    initialValue={item.buildingFloorAreaInSquareMeters}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateField('buildingFloorAreaInSquareMeters', newValue)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.buildingFloorAreaInSquareMeters"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingFoundationTypeId')}</th>
                            <td>
                                <SearchInternalListLocal
                                    listByType={listByType}
                                    name="buildingFoundationTypeId"
                                    type="PROJECT_BUILDING_FOUNDATION_TYPE"
                                    selectedId={item.buildingFoundationTypeId}
                                    onChangeSelectedItem={(newItem) => updateInternalListField('buildingFoundationTypeId', newItem)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.buildingFoundationTypeId"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingExteriorCladdingTypes.title')}</th>
                            <td>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th>{t('project.buildingExteriorCladdingTypes.frontFacing')}</th>
                                        <td>
                                            <ListSearchInternalListWithPrecisionLocal
                                                listByType={listByType}
                                                name="buildingExteriorCladdingTypes.frontFacing"
                                                type="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE"
                                                precisionType="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE_PRECISION"
                                                selectedEntries={item.buildingExteriorCladdingTypes?.frontFacing}
                                                onChangeSelectedEntries={(newEntries) => updateInternalListIdAndPrecisionFieldList('buildingExteriorCladdingTypes.frontFacing', newEntries)}
                                            />
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingExteriorCladdingTypes.frontFacing"/>
                                        </td>
                                    </tr>
                                    {item.buildingPhysicalLinkId === null || !internalListById[item.buildingPhysicalLinkId]?.features.includes('inARow') &&
                                        <>
                                            <tr>
                                                <th>{t('project.buildingExteriorCladdingTypes.rightFacing')}</th>
                                                <td>
                                                    <CheckboxBoolean
                                                        label={t('common.notApplicable')}
                                                        name="buildingExteriorCladdingTypes.rightFacingNotApplicable"
                                                        value={item.buildingExteriorCladdingTypes?.rightFacingNotApplicable}
                                                        onChangeValue={newValue => updateFieldEvenWhenNull('buildingExteriorCladdingTypes.rightFacingNotApplicable', newValue)}
                                                    />
                                                    {!item.buildingExteriorCladdingTypes?.rightFacingNotApplicable &&
                                                        <ListSearchInternalListWithPrecisionLocal
                                                            listByType={listByType}
                                                            name="buildingExteriorCladdingTypes.rightFacing"
                                                            type="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE"
                                                            precisionType="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE_PRECISION"
                                                            selectedEntries={item.buildingExteriorCladdingTypes?.rightFacing}
                                                            onChangeSelectedEntries={(newEntries) => updateInternalListIdAndPrecisionFieldList('buildingExteriorCladdingTypes.rightFacing', newEntries)}
                                                        />
                                                    }
                                                    <ProjectWarnings project={item}
                                                                     fieldName="residentialBuildingDescription.buildingExteriorCladdingTypes.rightFacing"/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.buildingExteriorCladdingTypes.leftFacing')}</th>
                                                <td>
                                                    <CheckboxBoolean
                                                        label={t('common.notApplicable')}
                                                        name="hasDependencies.leftFacingNotApplicable"
                                                        value={item.buildingExteriorCladdingTypes?.leftFacingNotApplicable}
                                                        onChangeValue={newValue => updateFieldEvenWhenNull('buildingExteriorCladdingTypes.leftFacingNotApplicable', newValue)}
                                                    />
                                                    {!item.buildingExteriorCladdingTypes?.leftFacingNotApplicable &&
                                                        <ListSearchInternalListWithPrecisionLocal
                                                            listByType={listByType}
                                                            name="buildingExteriorCladdingTypes.leftFacing"
                                                            type="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE"
                                                            precisionType="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE_PRECISION"
                                                            selectedEntries={item.buildingExteriorCladdingTypes?.leftFacing}
                                                            onChangeSelectedEntries={(newEntries) => updateInternalListIdAndPrecisionFieldList('buildingExteriorCladdingTypes.leftFacing', newEntries)}
                                                        />
                                                    }
                                                    <ProjectWarnings project={item}
                                                                     fieldName="residentialBuildingDescription.buildingExteriorCladdingTypes.leftFacing"/>
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    <tr>
                                        <th>{t('project.buildingExteriorCladdingTypes.rearFacing')}</th>
                                        <td>
                                            <ListSearchInternalListWithPrecisionLocal
                                                listByType={listByType}
                                                name="buildingExteriorCladdingTypes.rearFacing"
                                                type="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE"
                                                precisionType="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE_PRECISION"
                                                selectedEntries={item.buildingExteriorCladdingTypes?.rearFacing}
                                                onChangeSelectedEntries={(newEntries) => updateInternalListIdAndPrecisionFieldList('buildingExteriorCladdingTypes.rearFacing', newEntries)}
                                            />
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingExteriorCladdingTypes.rearFacing"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('project.buildingExteriorCladdingTypes.attachedGarage')}</th>
                                        <td>
                                            <CheckboxBoolean
                                                label={t('common.notApplicable')}
                                                name="buildingExteriorCladdingTypes.attachedGarageNotApplicable"
                                                value={item.buildingExteriorCladdingTypes?.attachedGarageNotApplicable}
                                                onChangeValue={newValue => updateFieldEvenWhenNull('buildingExteriorCladdingTypes.attachedGarageNotApplicable', newValue)}
                                            />
                                            {!item.buildingExteriorCladdingTypes?.attachedGarageNotApplicable &&
                                                <ListSearchInternalListWithPrecisionLocal
                                                    listByType={listByType}
                                                    name="buildingExteriorCladdingTypes.attachedGarage"
                                                    type="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE"
                                                    precisionType="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE_PRECISION"
                                                    selectedEntries={item.buildingExteriorCladdingTypes?.attachedGarage}
                                                    onChangeSelectedEntries={(newEntries) => updateInternalListIdAndPrecisionFieldList('buildingExteriorCladdingTypes.attachedGarage', newEntries)}
                                                />
                                            }
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingExteriorCladdingTypes.attachedGarage"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('project.buildingExteriorCladdingTypes.integratedGarage')}</th>
                                        <td>
                                            <CheckboxBoolean
                                                label={t('common.notApplicable')}
                                                name="buildingExteriorCladdingTypes.integratedGarageNotApplicable"
                                                value={item.buildingExteriorCladdingTypes?.integratedGarageNotApplicable}
                                                onChangeValue={newValue => updateFieldEvenWhenNull('buildingExteriorCladdingTypes.integratedGarageNotApplicable', newValue)}
                                            />
                                            {!item.buildingExteriorCladdingTypes?.integratedGarageNotApplicable &&
                                                <ListSearchInternalListWithPrecisionLocal
                                                    listByType={listByType}
                                                    name="buildingExteriorCladdingTypes.integratedGarage"
                                                    type="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE"
                                                    precisionType="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE_PRECISION"
                                                    selectedEntries={item.buildingExteriorCladdingTypes?.integratedGarage}
                                                    onChangeSelectedEntries={(newEntries) => updateInternalListIdAndPrecisionFieldList('buildingExteriorCladdingTypes.integratedGarage', newEntries)}
                                                />
                                            }
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingExteriorCladdingTypes.integratedGarage"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('project.buildingExteriorCladdingTypes.extension')}</th>
                                        <td>
                                            <CheckboxBoolean
                                                label={t('common.notApplicable')}
                                                name="buildingExteriorCladdingTypes.extensionNotApplicable"
                                                value={item.buildingExteriorCladdingTypes?.extensionNotApplicable}
                                                onChangeValue={newValue => updateFieldEvenWhenNull('buildingExteriorCladdingTypes.extensionNotApplicable', newValue)}
                                            />
                                            {!item.buildingExteriorCladdingTypes?.extensionNotApplicable &&
                                                <ListSearchInternalListWithPrecisionLocal
                                                    listByType={listByType}
                                                    name="buildingExteriorCladdingTypes.extension"
                                                    type="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE"
                                                    precisionType="PROJECT_BUILDING_EXTERIOR_CLADDING_TYPE_PRECISION"
                                                    selectedEntries={item.buildingExteriorCladdingTypes?.extension}
                                                    onChangeSelectedEntries={(newEntries) => updateInternalListIdAndPrecisionFieldList('buildingExteriorCladdingTypes.extension', newEntries)}
                                                />
                                            }
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingExteriorCladdingTypes.extension"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingDoorTypes.title')}</th>
                            <td>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th>{t('project.buildingDoorTypes.mainEntranceId')}</th>
                                        <td>
                                            <SearchInternalListLocal
                                                listByType={listByType}
                                                name="buildingDoorTypes.mainEntranceId"
                                                type="PROJECT_BUILDING_DOOR_TYPE"
                                                selectedId={item.buildingDoorTypes?.mainEntranceId}
                                                onChangeSelectedItem={(newItem) => updateInternalListField('buildingDoorTypes.mainEntranceId', newItem)}
                                            />
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingDoorTypes.mainEntranceId"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('project.buildingDoorTypes.secondaryEntranceIds')}</th>
                                        <td>
                                            <CheckboxBoolean
                                                label={t('common.notApplicable')}
                                                name="buildingDoorTypes.secondaryEntranceNotApplicable"
                                                value={item.buildingDoorTypes?.secondaryEntranceNotApplicable}
                                                onChangeValue={newValue => updateFieldEvenWhenNull('buildingDoorTypes.secondaryEntranceNotApplicable', newValue)}
                                            />
                                            {!item.buildingDoorTypes?.secondaryEntranceNotApplicable &&
                                                <ListSearchInternalListLocal
                                                    listByType={listByType}
                                                    name="buildingDoorTypes.secondaryEntranceIds"
                                                    type="PROJECT_BUILDING_DOOR_TYPE"
                                                    selectedIds={item.buildingDoorTypes?.secondaryEntranceIds}
                                                    startWithARow={true}
                                                    onChangeSelectedItems={(newItems) => updateInternalListFieldList('buildingDoorTypes.secondaryEntranceIds', newItems)}
                                                />
                                            }
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingDoorTypes.secondaryEntranceIds"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('project.buildingDoorTypes.patioId')}</th>
                                        <td>
                                            <SearchInternalListLocal
                                                listByType={listByType}
                                                name="buildingDoorTypes.patioId"
                                                type="PROJECT_BUILDING_DOOR_TYPE"
                                                selectedId={item.buildingDoorTypes?.patioId}
                                                onChangeSelectedItem={(newItem) => updateInternalListField('buildingDoorTypes.patioId', newItem)}
                                            />
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingDoorTypes.patioId"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('project.buildingDoorTypes.garageEntranceId')}</th>
                                        <td>
                                            <SearchInternalListLocal
                                                listByType={listByType}
                                                name="buildingDoorTypes.garageEntranceId"
                                                type="PROJECT_BUILDING_DOOR_TYPE"
                                                selectedId={item.buildingDoorTypes?.garageEntranceId}
                                                onChangeSelectedItem={(newItem) => updateInternalListField('buildingDoorTypes.garageEntranceId', newItem)}
                                            />
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingDoorTypes.garageEntranceId"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('project.buildingDoorTypes.interiorGarageId')}</th>
                                        <td>
                                            <SearchInternalListLocal
                                                listByType={listByType}
                                                name="buildingDoorTypes.interiorGarageId"
                                                type="PROJECT_BUILDING_DOOR_TYPE"
                                                selectedId={item.buildingDoorTypes?.interiorGarageId}
                                                onChangeSelectedItem={(newItem) => updateInternalListField('buildingDoorTypes.interiorGarageId', newItem)}
                                            />
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingDoorTypes.interiorGarageId"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('project.buildingDoorTypes.emergencyExitIds')}</th>
                                        <td>
                                            <CheckboxBoolean
                                                label={t('common.notApplicable')}
                                                name="buildingDoorTypes.emergencyExitNotApplicable"
                                                value={item.buildingDoorTypes?.emergencyExitNotApplicable}
                                                onChangeValue={newValue => updateFieldEvenWhenNull('buildingDoorTypes.emergencyExitNotApplicable', newValue)}
                                            />
                                            {!item.buildingDoorTypes?.emergencyExitNotApplicable &&
                                                <ListSearchInternalListLocal
                                                    listByType={listByType}
                                                    name="buildingDoorTypes.emergencyExitIds"
                                                    type="PROJECT_BUILDING_DOOR_TYPE"
                                                    selectedIds={item.buildingDoorTypes?.emergencyExitIds}
                                                    startWithARow={true}
                                                    onChangeSelectedItems={(newItems) => updateInternalListFieldList('buildingDoorTypes.emergencyExitIds', newItems)}
                                                />
                                            }
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingDoorTypes.emergencyExitIds"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingWindowTypes.title')}</th>
                            <td>
                                <table className="table">
                                    <tbody>
                                    {!item.buildingWindowTypes?.basementId &&
                                        <tr>
                                            <th>{t('project.buildingWindowTypes.crawlspaceId')}</th>
                                            <td>
                                                <CheckboxBoolean
                                                    label={t('common.notApplicable')}
                                                    name="buildingWindowTypes.crawlspaceNotApplicable"
                                                    value={item.buildingWindowTypes?.crawlspaceNotApplicable}
                                                    onChangeValue={newValue => updateFieldEvenWhenNull('buildingWindowTypes.crawlspaceNotApplicable', newValue)}
                                                />
                                                {!item.buildingWindowTypes?.crawlspaceNotApplicable &&
                                                    <SearchInternalListLocal
                                                        listByType={listByType}
                                                        name="buildingWindowTypes.crawlspaceId"
                                                        type="PROJECT_BUILDING_WINDOW_TYPE"
                                                        selectedId={item.buildingWindowTypes?.crawlspaceId}
                                                        onChangeSelectedItem={(newItem) => updateInternalListField('buildingWindowTypes.crawlspaceId', newItem, true)}
                                                    />
                                                }
                                                <ProjectWarnings project={item}
                                                                 fieldName="residentialBuildingDescription.buildingWindowTypes.crawlspaceId"/>
                                            </td>
                                        </tr>
                                    }
                                    {!item.buildingWindowTypes?.crawlspaceId &&
                                        <tr>
                                            <th>{t('project.buildingWindowTypes.basementId')}</th>
                                            <td>
                                                <CheckboxBoolean
                                                    label={t('common.notApplicable')}
                                                    name="buildingWindowTypes.baseNotApplicable"
                                                    value={item.buildingWindowTypes?.basementNotApplicable}
                                                    onChangeValue={newValue => updateFieldEvenWhenNull('buildingWindowTypes.basementNotApplicable', newValue)}
                                                />
                                                {!item.buildingWindowTypes?.basementNotApplicable &&
                                                    <SearchInternalListLocal
                                                        listByType={listByType}
                                                        name="buildingWindowTypes.basementId"
                                                        type="PROJECT_BUILDING_WINDOW_TYPE"
                                                        selectedId={item.buildingWindowTypes?.basementId}
                                                        onChangeSelectedItem={(newItem) => updateInternalListField('buildingWindowTypes.basementId', newItem, true)}
                                                    />
                                                }
                                                <ProjectWarnings project={item}
                                                                 fieldName="residentialBuildingDescription.buildingWindowTypes.basementId"/>
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <th>{t('project.buildingWindowTypes.idByFloor')}</th>
                                        <td>
                                            <MapSearchInternalListLocal
                                                listByType={listByType}
                                                name="buildingWindowTypes.idByFloor"
                                                type="PROJECT_BUILDING_WINDOW_TYPE"
                                                map={item.buildingWindowTypes?.idByFloor}
                                                newKeyProvider={() => {
                                                    // Check the keys of the map and chose the first missing number starting from 1
                                                    let nextNumber = '1'
                                                    while (item?.buildingWindowTypes?.idByFloor[nextNumber]) {
                                                        nextNumber = (parseInt(nextNumber) + 1).toString()
                                                    }
                                                    return nextNumber
                                                }
                                                }
                                                startWithARow={true}
                                                onChangeMap={(newMap) => updateField('buildingWindowTypes.idByFloor', newMap)}
                                            />
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingWindowTypes.idByFloor"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingRoofTypes.title')}</th>
                            <td>
                                <table className="table">
                                    <tbody>
                                    <tr>
                                        <th>{t('project.buildingRoofTypes.structureId')}</th>
                                        <td>
                                            <SearchInternalListLocal
                                                listByType={listByType}
                                                name="buildingRoofTypes.structureId"
                                                type="PROJECT_BUILDING_ROOFING_STRUCTURE"
                                                selectedId={item.buildingRoofTypes?.structureId}
                                                onChangeSelectedItem={(newItem) => updateInternalListField('buildingRoofTypes.structureId', newItem)}
                                            />
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingRoofTypes.structureId"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>{t('project.buildingRoofTypes.coveringId')}</th>
                                        <td>
                                            <SearchInternalListLocal
                                                listByType={listByType}
                                                name="buildingRoofTypes.coveringId"
                                                type="PROJECT_BUILDING_ROOFING_COVERING"
                                                selectedId={item.buildingRoofTypes?.coveringId}
                                                onChangeSelectedItem={(newItem) => updateInternalListField('buildingRoofTypes.coveringId', newItem)}
                                            />
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingDescription.buildingRoofTypes.coveringId"/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}><h4>{t('project.plumbingInstallationTitle')}</h4></td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingDrinkingWaterSupplyIds')}</th>
                            <td>
                                <CheckboxesInternalListLocal
                                    listByType={listByType}
                                    name="buildingDrinkingWaterSupplyIds"
                                    type="PROJECT_BUILDING_DRINKING_WATER_SUPPLY"
                                    selectedIds={item.buildingDrinkingWaterSupplyIds}
                                    onChangeSelectedItems={(newItems) => updateInternalListFieldList('buildingDrinkingWaterSupplyIds', newItems)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.buildingDrinkingWaterSupplyIds"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.hotWaterProductionDeviceIdAndPrecisionId')}</th>
                            <td>
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceIdAndPrecisionId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDevicePrecisionWaterHeaterOther"
                                    labelId="project.hotWaterProductionDevicePrecisionWaterHeaterOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDevicePrecisionOther"
                                    labelId="project.hotWaterProductionDevicePrecisionOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceEnergySourceId"
                                    labelId="project.hotWaterProductionDeviceEnergySourceId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceEnergySourceIdOther"
                                    labelId="project.hotWaterProductionDeviceEnergySourceIdOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceCapacity"
                                    labelId="project.hotWaterProductionDeviceCapacity"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceSerialNumber"
                                    labelId="project.hotWaterProductionDeviceSerialNumber"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceManufacturerId"
                                    labelId="project.hotWaterProductionDeviceManufacturerId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceManufacturerIdOther"
                                    labelId="project.hotWaterProductionDeviceManufacturerIdOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceBoilerManufacturerId"
                                    labelId="project.hotWaterProductionDeviceManufacturerId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceBoilerManufacturerIdOther"
                                    labelId="project.hotWaterProductionDeviceManufacturerIdOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceManufactureDate"
                                    labelId="project.hotWaterProductionDeviceManufactureDate"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceManufacturerWarranty"
                                    labelId="project.hotWaterProductionDeviceManufacturerWarranty"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceInstallationSafetyId"
                                    labelId="project.hotWaterProductionDeviceInstallationSafetyId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceInstallationSafetyHotWaterTankUnsafeMotiveId"
                                    labelId="project.hotWaterProductionDeviceInstallationSafetyHotWaterTankUnsafeMotiveId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceInstallationSafetyHotWaterTankUnsafeMotiveOther"
                                    labelId="project.hotWaterProductionDeviceInstallationSafetyHotWaterTankUnsafeMotiveOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceInstallationSafetyBoilerUnsafeMotiveId"
                                    labelId="project.hotWaterProductionDeviceInstallationSafetyBoilerUnsafeMotiveId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceInstallationSafetyBoilerUnsafeMotiveOther"
                                    labelId="project.hotWaterProductionDeviceInstallationSafetyBoilerUnsafeMotiveOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="hotWaterProductionDeviceInstallationSafetyBoilerUnsafeFailureIds"
                                    labelId="project.hotWaterProductionDeviceInstallationSafetyBoilerUnsafeFailureIds"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingSewageAndSanitaryWasteDisposalIds')}</th>
                            <td>
                                <CheckboxesInternalListLocal
                                    listByType={listByType}
                                    name="buildingSewageAndSanitaryWasteDisposalIds"
                                    type="PROJECT_BUILDING_SEWAGE_AND_SANITARY_WASTE_DISPOSAL"
                                    selectedIds={item.buildingSewageAndSanitaryWasteDisposalIds}
                                    onChangeSelectedItems={(newItems) => updateInternalListFieldList('buildingSewageAndSanitaryWasteDisposalIds', newItems)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.buildingSewageAndSanitaryWasteDisposalIds"/>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}><h4>{t('project.electricalInstallationTitle')}</h4></td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingElectricPowerSupplierIds')}</th>
                            <td>
                                <CheckboxesInternalListLocal
                                    listByType={listByType}
                                    name="buildingElectricPowerSupplierIds"
                                    type="PROJECT_BUILDING_ELECTRIC_POWER_SUPPLIER"
                                    selectedIds={item.buildingElectricPowerSupplierIds}
                                    onChangeSelectedItems={(newItems) => updateInternalListFieldList('buildingElectricPowerSupplierIds', newItems)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.buildingElectricPowerSupplierIds"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingElectricInput')}</th>
                            <td>
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="buildingElectricInputTypeId"
                                    labelId="project.buildingElectricInputTypeId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="buildingElectricInputTypeOther"
                                    labelId="project.buildingElectricInputTypeOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="buildingElectricInputInstallationSafetyId"
                                    labelId="project.buildingElectricInputInstallationSafetyId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="buildingElectricInputInstallationSafetyUnsafeMotiveId"
                                    labelId="project.buildingElectricInputInstallationSafetyUnsafeMotiveId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="buildingElectricInputInstallationSafetyUnsafeMotiveOther"
                                    labelId="project.buildingElectricInputInstallationSafetyUnsafeMotiveOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingElectricMeter')}</th>
                            <td>
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="buildingElectricMeterTypeId"
                                    labelId="project.buildingElectricMeterTypeId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="buildingElectricMeterTypeOther"
                                    labelId="project.buildingElectricMeterTypeOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="buildingElectricMeterManufacturerId"
                                    labelId="project.buildingElectricMeterManufacturerId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="buildingElectricMeterManufacturerUnidentifiedMotiveId"
                                    labelId="project.buildingElectricMeterManufacturerUnidentifiedMotiveId"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="buildingElectricMeterManufacturerUnidentifiedMotiveOther"
                                    labelId="project.buildingElectricMeterManufacturerUnidentifiedMotiveOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                                <AutoFormField
                                    fields={automaticForm}
                                    fieldName="buildingElectricMeterManufacturerOther"
                                    labelId="project.buildingElectricMeterManufacturerOther"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingElectricMainDistributionPanel')}</th>
                            <td>
                                <ElectricDistributionPanel
                                    fields={automaticForm}
                                    fieldName="buildingElectricMainDistributionPanel"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                    onAddToMany={updateAutomaticOnAddToMany}
                                    onRemoveFromMany={updateAutomaticOnRemoveFromMany}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingElectricSecondaryDistributionPanels')}</th>
                            <td>
                                <ElectricDistributionPanel
                                    fields={automaticForm}
                                    fieldName="buildingElectricSecondaryDistributionPanels"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                    onAddToMany={updateAutomaticOnAddToMany}
                                    onRemoveFromMany={updateAutomaticOnRemoveFromMany}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingElectricGeneratorDistributionPanels')}</th>
                            <td>
                                <ElectricDistributionPanel
                                    fields={automaticForm}
                                    fieldName="buildingElectricGeneratorDistributionPanels"
                                    project={item}
                                    listByType={listByType}
                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItem}
                                    onAddToMany={updateAutomaticOnAddToMany}
                                    onRemoveFromMany={updateAutomaticOnRemoveFromMany}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingMainHeatingSystemEnergySourceId')}</th>
                            <td>
                                <SearchInternalListLocal
                                    listByType={listByType}
                                    name="buildingMainHeatingSystemEnergySourceId"
                                    type="PROJECT_BUILDING_HEATING_ENERGY_SOURCE"
                                    selectedId={item.buildingMainHeatingSystemEnergySourceId}
                                    onChangeSelectedItem={(newItem) => updateInternalListField('buildingMainHeatingSystemEnergySourceId', newItem)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.buildingMainHeatingSystemEnergySourceId"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.buildingAuxiliaryHeatingSystemEnergySourceId')}</th>
                            <td>
                                <CheckboxBoolean
                                    label={t('common.notApplicable')}
                                    name="buildingAuxiliaryHeatingSystemEnergySourceNotApplicable"
                                    value={item.buildingAuxiliaryHeatingSystemEnergySourceNotApplicable}
                                    onChangeValue={newValue => updateFieldEvenWhenNull('buildingAuxiliaryHeatingSystemEnergySourceNotApplicable', newValue)}
                                />
                                {!item.buildingAuxiliaryHeatingSystemEnergySourceNotApplicable &&
                                    <SearchInternalListLocal
                                        listByType={listByType}
                                        name="buildingAuxiliaryHeatingSystemEnergySourceId"
                                        type="PROJECT_BUILDING_AUXILIARY_HEATING_ENERGY_SOURCE"
                                        selectedId={item.buildingAuxiliaryHeatingSystemEnergySourceId}
                                        onChangeSelectedItem={(newItem) => updateInternalListField('buildingAuxiliaryHeatingSystemEnergySourceId', newItem)}
                                    />
                                }
                                <ProjectWarnings project={item}
                                                 fieldName="residentialBuildingDescription.buildingAuxiliaryHeatingSystemEnergySourceId"/>
                                {item.buildingAuxiliaryHeatingSystemEnergySourceId && <>
                                    <strong>{t('common.precision')}</strong>
                                    <SearchInternalListLocal
                                        listByType={listByType}
                                        name="buildingAuxiliaryHeatingSystemEnergySourcePrecisionId"
                                        type="PROJECT_BUILDING_AUXILIARY_HEATING_ENERGY_SOURCE_PRECISION"
                                        dependOnId={item.buildingAuxiliaryHeatingSystemEnergySourceId}
                                        selectedId={item.buildingAuxiliaryHeatingSystemEnergySourcePrecisionId}
                                        onChangeSelectedItem={(newItem) => updateInternalListField('buildingAuxiliaryHeatingSystemEnergySourcePrecisionId', newItem)}
                                    />
                                    <ProjectWarnings project={item}
                                                     fieldName="residentialBuildingDescription.buildingAuxiliaryHeatingSystemEnergySourcePrecisionId"/>
                                </>}
                                {internalListById[item.buildingAuxiliaryHeatingSystemEnergySourceId]?.features.includes('ventilation') && <>
                                    <strong>{t('project.buildingAuxiliaryHeatingSystemVentilationId')}</strong>
                                    <SearchInternalListLocal
                                        listByType={listByType}
                                        name="buildingAuxiliaryHeatingSystemVentilationId"
                                        type="PROJECT_BUILDING_AUXILIARY_HEATING_VENTILATION"
                                        selectedId={item.buildingAuxiliaryHeatingSystemVentilationId}
                                        onChangeSelectedItem={(newItem) => updateInternalListField('buildingAuxiliaryHeatingSystemVentilationId', newItem)}
                                    />
                                    <ProjectWarnings project={item}
                                                     fieldName="residentialBuildingDescription.buildingAuxiliaryHeatingSystemVentilationId"/>
                                </>}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#ownersSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="owners"><i className="bi bi-person menuIcon"/>
                    {t('project.owners')}
                    <ProjectWarningsBadge project={item} prefix="owners."/>
                </h3>

                <div id="ownersSection" className="collapse">
                    <button className="btn btn-success float-end"
                            onClick={() => setShowCreateOwner(true)}
                    >+
                    </button>

                    <div className="clearfix"></div>

                    <ProjectWarnings project={item} fieldName="owners"/>

                    {showCreateOwner && <div>
                        <OwnerCreateEditComponent
                            googleMapsApiReady={props.googleMapsApiReady}
                            project={item}
                            updated={ownerCreated}
                            cancel={() => setShowCreateOwner(false)}
                        />
                    </div>
                    }

                    {item.owners?.map(owner => (<div key={owner.id}>
                            {editOwnerEditByIds[owner.id] &&
                                <OwnerCreateEditComponent
                                    googleMapsApiReady={props.googleMapsApiReady}
                                    project={item}
                                    initialOwner={owner}
                                    updated={project => ownerUpdated(owner.id, project)}
                                    cancel={() => toggleCheckbox(editOwnerEditByIds, setEditOwnerEditByIds, owner.id)}
                                />
                            }

                            {!editOwnerEditByIds[owner.id] &&
                                <div className="roundCard">

                                    <button className="btn btn-danger float-end" type="button"
                                            onClick={() => deleteOwner(owner.id)}>
                                        X
                                    </button>
                                    <button className="btn btn-primary float-end" type="button"
                                            onClick={() => toggleCheckbox(editOwnerEditByIds, setEditOwnerEditByIds, owner.id)}>
                                        <i className="bi bi-pencil-square"></i>
                                    </button>

                                    <table className="table">
                                        <tbody>
                                        <tr>
                                            <th>{t('project.owner.typeId')}</th>
                                            <td>
                                                {internalListById[owner.typeId]?.value[toLangOnly(i18n.language)]}
                                                <ProjectWarnings project={item}
                                                                 fieldName={`owners.${owner.id}.typeId`}/>
                                            </td>
                                        </tr>
                                        {internalListById[owner.typeId]?.features.includes('individual') && <>
                                            <tr>
                                                <th>{t('project.owner.firstName')}</th>
                                                <td>
                                                    {owner.firstName}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`owners.${owner.id}.firstName`}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.owner.lastName')}</th>
                                                <td>
                                                    {owner.lastName}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`owners.${owner.id}.lastName`}/>
                                                </td>
                                            </tr>
                                        </>
                                        }
                                        {internalListById[owner.typeId]?.features.includes('corporation') && <>
                                            <tr>
                                                <th>{t('project.owner.corporationName')}</th>
                                                <td>
                                                    {owner.corporationName}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.owner.representativeFirstName')}</th>
                                                <td>
                                                    {owner.representativeFirstName}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`owners.${owner.id}.representativeFirstName`}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.owner.representativeLastName')}</th>
                                                <td>
                                                    {owner.representativeLastName}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`owners.${owner.id}.representativeLastName`}/>
                                                </td>
                                            </tr>
                                        </>
                                        }
                                        <tr>
                                            <th>{t('project.owner.currentAddress')}</th>
                                            <td>
                                                {owner.currentAddress?.full}
                                                <ProjectWarnings project={item}
                                                                 fieldName={`owners.${owner.id}.currentAddress`}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('project.owner.phoneNumber')}</th>
                                            <td>
                                                {owner.phoneNumber}
                                                <ProjectWarnings project={item}
                                                                 fieldName={`owners.${owner.id}.phoneNumber`}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>{t('project.owner.email')}</th>
                                            <td>
                                                {owner.email}
                                                <ProjectWarnings project={item}
                                                                 fieldName={`owners.${owner.id}.email`}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} className="text-center">
                                                {owner.availableDuringInspection ? t('project.owner.availableDuringInspection') : t('project.owner.notAvailableDuringInspection')}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    ))}
                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#residentialBuildingPartSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="residentialBuildingPart"><i
                    className="bi bi-eye menuIcon"/>
                    {t('project.residentialBuildingPart')}
                    <ProjectWarningsBadge project={item} prefix="residentialBuildingPart."/>
                </h3>

                <div id="residentialBuildingPartSection" className="collapse">
                    <table className="table">
                        <tbody>
                        {item.transactionPurposeId &&
                            <tr>
                                <th>{t('project.residentialBuildingPartTypeIds')}</th>
                                <td>
                                    <ProjectWarnings project={item}
                                                     fieldName="residentialBuildingPart.residentialBuildingPartTypeIds"/>

                                    <CheckboxesInternalListLocal
                                        listByType={listByType}
                                        name="residentialBuildingPartTypeIds"
                                        type="PROJECT_BUILDING_PART_TYPE"
                                        dependOnId={internalListById[item.transactionPurposeId]?.features[0]}
                                        selectedIds={item.residentialBuildingPartTypeIds}
                                        onChangeSelectedItems={(newItems) => updateInternalListFieldList('residentialBuildingPartTypeIds', newItems)}
                                    />

                                    {residentialBuildingPartTypeIdsFeatures.includes('privateUnitNumber') && <>
                                        <br/>
                                        <strong>{t('project.privateUnitNumber')}</strong>
                                        <DelayedInput
                                            type="number" className="form-control"
                                            name="privateUnitNumber"
                                            initialValue={item.privateUnitNumber}
                                            waitTimeInMs={1000}
                                            onChange={newValue => updateField('privateUnitNumber', newValue)}
                                        />
                                        <br/>
                                        <ProjectWarnings project={item}
                                                         fieldName="residentialBuildingPart.privateUnitNumber"/>
                                    </>}
                                </td>
                            </tr>
                        }
                        {residentialBuildingPartTypeIdsFeatures.includes('vocation') && <>
                            <tr>
                                <th>{t('project.vocation')}</th>
                                <td>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                               id="vocationCommercialUnitsCount"
                                               name="vocationCommercialUnitsCount"
                                               checked={item.vocationCommercialUnitsCount}
                                               onChange={toggleVocationCommercialUnitsCount}
                                        />
                                        <ProjectWarnings project={item}
                                                         fieldName="residentialBuildingPart.vocationCommercialUnitsCount"/>
                                        <label className="form-check-label" htmlFor="vocationCommercialUnitsCount">
                                            {t('project.vocationCommercialUnitsCount')}
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                               id="vocationHousingUnitsCount"
                                               name="vocationHousingUnitsCount"
                                               checked={item.vocationHousingUnitsCount}
                                               onChange={toggleVocationHousingUnitsCount}
                                        />
                                        <ProjectWarnings project={item}
                                                         fieldName="residentialBuildingPart.vocationHousingUnitsCount"/>
                                        <label className="form-check-label" htmlFor="vocationHousingUnitsCount">
                                            {t('project.vocationHousingUnitsCount')}
                                        </label>
                                    </div>
                                </td>
                            </tr>
                        </>}
                        {residentialBuildingPartTypeIdsFeatures.includes('vocations') && <>
                            <tr>
                                <th>{t('project.vocations')}</th>
                                <td>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                               id="vocationCommercialUnitsCountCheck"
                                               name="vocationCommercialUnitsCount"
                                               checked={item.vocationCommercialUnitsCount}
                                               onChange={toggleVocationCommercialUnitsCount}
                                        />
                                        <ProjectWarnings project={item}
                                                         fieldName="residentialBuildingPart.vocationCommercialUnitsCount"/>
                                        <label className="form-check-label"
                                               htmlFor="vocationCommercialUnitsCountCheck">
                                            {t('project.vocationCommercialUnitsCount')}
                                        </label>

                                        <div className="input-group">
                                            <label htmlFor="vocationCommercialUnitsCount"
                                                   className="form-label">{t('common.number')}: </label>
                                            <DelayedInput
                                                type="number" className="form-control"
                                                id="vocationCommercialUnitsCount"
                                                name="vocationCommercialUnitsCount"
                                                min={0}
                                                hideCircle={true}
                                                initialValue={item.vocationCommercialUnitsCount}
                                                waitTimeInMs={1000}
                                                onChange={newValue => updateField('vocationCommercialUnitsCount', newValue)}
                                            />
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingPart.vocationCommercialUnitsCount"/>
                                        </div>

                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox"
                                               id="vocationHousingUnitsCountCheck"
                                               name="vocationHousingUnitsCount"
                                               checked={item.vocationHousingUnitsCount}
                                               onChange={toggleVocationHousingUnitsCount}
                                        />
                                        <ProjectWarnings project={item}
                                                         fieldName="residentialBuildingPart.vocationHousingUnitsCount"/>
                                        <label className="form-check-label"
                                               htmlFor="vocationHousingUnitsCountCheck">
                                            {t('project.vocationHousingUnitsCount')}
                                        </label>

                                        <div className="input-group">
                                            <label htmlFor="vocationHousingUnitsCount"
                                                   className="form-label">{t('common.number')}: </label>
                                            <DelayedInput
                                                type="number" className="form-control"
                                                id="vocationHousingUnitsCount"
                                                name="vocationHousingUnitsCount"
                                                min={0}
                                                hideCircle={true}
                                                initialValue={item.vocationHousingUnitsCount}
                                                waitTimeInMs={1000}
                                                onChange={newValue => updateField('vocationHousingUnitsCount', newValue)}
                                            />
                                            <ProjectWarnings project={item}
                                                             fieldName="residentialBuildingPart.vocationHousingUnitsCount"/>
                                        </div>

                                    </div>
                                </td>
                            </tr>
                        </>}
                        {internalListById[item.transactionPurposeId]?.features.includes('privateUnitOfResidentialBuilding') && <>
                            {!item.nameOfCoOwnerCooperative &&
                                <tr>
                                    <th>{t('project.nameOfSyndicateOfCoOwnership')}</th>
                                    <td>
                                        <DelayedInput
                                            type="text" className="form-control"
                                            name="nameOfSyndicateOfCoOwnership"
                                            initialValue={item.nameOfSyndicateOfCoOwnership}
                                            waitTimeInMs={1000}
                                            onChange={newValue => updateFieldEvenWhenNull('nameOfSyndicateOfCoOwnership', newValue)}
                                        />
                                        <ProjectWarnings project={item}
                                                         fieldName="residentialBuildingPart.nameOfSyndicateOfCoOwnership"/>
                                    </td>
                                </tr>
                            }
                            {!item.nameOfSyndicateOfCoOwnership &&
                                <tr>
                                    <th>{t('project.nameOfCoOwnerCooperative')}</th>
                                    <td>
                                        <DelayedInput
                                            type="text" className="form-control"
                                            name="nameOfCoOwnerCooperative"
                                            initialValue={item.nameOfCoOwnerCooperative}
                                            waitTimeInMs={1000}
                                            onChange={newValue => updateFieldEvenWhenNull('nameOfCoOwnerCooperative', newValue)}
                                        />
                                    </td>
                                </tr>
                            }
                        </>}
                        </tbody>
                    </table>
                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#dependenciesSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="dependencies"><i
                    className="bi bi-building-gear menuIcon"/>
                    {t('project.dependencies.title')}
                    <ProjectWarningsBadge project={item} prefix="dependencies."/>
                </h3>

                <div id="dependenciesSection" className="collapse">
                    <table className="table">
                        <tbody>
                        <tr>
                            <th>{t('project.hasDependencies')}</th>
                            <td>
                                <RadioButtonYesNo
                                    name="hasDependencies"
                                    value={item.hasDependencies}
                                    onChangeValue={newValue => updateFieldEvenWhenNull('hasDependencies', newValue)}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.dependencyDescriptionIds')}</th>
                            <td>
                                {item.hasDependencies && <>
                                    <CheckboxesInternalListLocal
                                        listByType={listByType}
                                        name="dependencyDescriptionIds"
                                        type="PROJECT_DEPENDENCY_DESCRIPTION"
                                        selectedIds={item.dependencyDescriptionIds}
                                        onChangeSelectedItems={(newItems) => updateInternalListFieldList('dependencyDescriptionIds', newItems)}
                                    />
                                    <ProjectWarnings project={item}
                                                     fieldName="dependencies.dependencyDescriptionIds"/>
                                </>}
                                {!item.hasDependencies && <>
                                    {t('common.none')}
                                </>}
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#presentPersonsSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="presentPersons"><i className="bi bi-person-check menuIcon"/>
                    {t('project.presentPersons')}
                    <ProjectWarningsBadge project={item} prefix="presentPersons"/>
                </h3>

                <div id="presentPersonsSection" className="collapse">
                    <button className="btn btn-success float-end"
                            onClick={() => setShowCreatePresentPerson(true)}
                    >+
                    </button>

                    <div className="clearfix"></div>

                    {
                        showCreatePresentPerson && <div>
                            <PresentPersonCreateEditComponent
                                project={item}
                                updated={presentPersonCreated}
                                cancel={() => setShowCreatePresentPerson(false)}
                            />
                        </div>
                    }

                    {
                        item.presentPersons?.map(presentPerson => (<div key={presentPerson.id}>
                                {editPresentPersonEditByIds[presentPerson.id] &&
                                    <PresentPersonCreateEditComponent
                                        project={item}
                                        initialPresentPerson={presentPerson}
                                        updated={project => presentPersonUpdated(presentPerson.id, project)}
                                        cancel={() => toggleCheckbox(editPresentPersonEditByIds, setEditPresentPersonEditByIds, presentPerson.id)}
                                    />
                                }

                                {!editPresentPersonEditByIds[presentPerson.id] &&
                                    <div className="roundCard">

                                        <button className="btn btn-danger float-end" type="button"
                                                onClick={() => deletePresentPerson(presentPerson.id)}>
                                            X
                                        </button>
                                        <button className="btn btn-primary float-end" type="button"
                                                onClick={() => toggleCheckbox(editPresentPersonEditByIds, setEditPresentPersonEditByIds, presentPerson.id)}>
                                            <i className="bi bi-pencil-square"></i>
                                        </button>

                                        <table className="table">
                                            <tbody>
                                            <tr>
                                                <th>{t('project.presentPerson.firstName')}</th>
                                                <td>
                                                    {presentPerson.firstName}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`presentPersons.${presentPerson.id}.firstName`}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.presentPerson.lastName')}</th>
                                                <td>
                                                    {presentPerson.lastName}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`presentPersons.${presentPerson.id}.lastName`}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.presentPerson.titleId')}</th>
                                                <td>
                                                    {internalListById[presentPerson.titleId]?.features.includes('other') && <>
                                                        {presentPerson.otherTitle}
                                                        <ProjectWarnings project={item}
                                                                         fieldName={`presentPersons.${presentPerson.id}.otherTitle`}/>
                                                    </>
                                                    }
                                                    {!internalListById[presentPerson.titleId]?.features.includes('other') && <>
                                                        {internalListById[presentPerson.titleId]?.value[toLangOnly(i18n.language)]}
                                                        <ProjectWarnings project={item}
                                                                         fieldName={`presentPersons.${presentPerson.id}.titleId`}/>
                                                    </>
                                                    }
                                                </td>
                                            </tr>

                                            <tr>
                                                <th>{t('project.presentPerson.durationId')}</th>
                                                <td>
                                                    {internalListById[presentPerson.durationId]?.value[toLangOnly(i18n.language)]}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`presentPersons.${presentPerson.id}.durationId`}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>{t('project.presentPerson.verifiedIdId')}</th>
                                                <td>
                                                    {internalListById[presentPerson.verifiedIdId]?.value[toLangOnly(i18n.language)]}
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`presentPersons.${presentPerson.id}.verifiedIdId`}/>
                                                </td>
                                            </tr>
                                            {presentPerson.notVerifiedIdId &&
                                                <tr>
                                                    <th>{t('project.presentPerson.notVerifiedIdId')}</th>
                                                    <td>
                                                        {internalListById[presentPerson.notVerifiedIdId]?.value[toLangOnly(i18n.language)]}
                                                        <ProjectWarnings project={item}
                                                                         fieldName={`presentPersons.${presentPerson.id}.notVerifiedIdId`}/>
                                                    </td>
                                                </tr>
                                            }
                                            {presentPerson.notVerifiedIdOther &&
                                                <tr>
                                                    <th>{t('project.presentPerson.notVerifiedIdOther')}</th>
                                                    <td>
                                                        {presentPerson.notVerifiedIdOther}
                                                    </td>
                                                </tr>
                                            }
                                            <tr>
                                                <td>
                                                    <ProjectWarnings project={item}
                                                                     fieldName={`presentPersons.${presentPerson.id}.notVerifiedIdOther`}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        ))
                    }
                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#weatherSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="weather"><i className="bi bi-cloud-sun menuIcon"/>
                    {t('project.weather')}
                    <ProjectWarningsBadge project={item} prefix="weather."/>
                </h3>

                <div id="weatherSection" className="collapse">
                    <table className="table">
                        <tbody>
                        <tr>
                            <th>{t('project.weatherId')}</th>
                            <td>
                                <SearchInternalListLocal
                                    listByType={listByType}
                                    name="weatherId"
                                    type="PROJECT_WEATHER"
                                    selectedId={item.weatherId}
                                    onChangeSelectedItem={(newItem) => updateInternalListField('weatherId', newItem)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="weather.weatherId"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.temperature')}</th>
                            <td>
                                <DelayedInput
                                    type="number" className="form-control"
                                    name="temperature"
                                    min={-100} max={100}
                                    initialValue={item.temperature}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateField('temperature', newValue)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="weather.temperature"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.feltTemperature')}</th>
                            <td>
                                <DelayedInput
                                    type="number" className="form-control"
                                    name="feltTemperature"
                                    min={-100} max={100}
                                    initialValue={item.feltTemperature}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateField('feltTemperature', newValue)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="weather.feltTemperature"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.relativeHumidity')}</th>
                            <td>
                                <DelayedInput
                                    type="number" className="form-control"
                                    name="relativeHumidity"
                                    min={0} max={100}
                                    initialValue={item.relativeHumidity}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateField('relativeHumidity', newValue)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="weather.relativeHumidity"/>
                            </td>
                        </tr>
                        <tr>
                            <th>{t('project.wind')}</th>
                            <td>
                                <DelayedInput
                                    type="number" className="form-control"
                                    name="wind"
                                    min={0} max={500}
                                    initialValue={item.wind}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateField('wind', newValue)}
                                />
                                <ProjectWarnings project={item}
                                                 fieldName="weather.wind"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#specialConditionsSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="specialConditions"><i
                    className="bi bi-clipboard menuIcon"/>{t('project.specialConditionsTitle')}</h3>

                <div id="specialConditionsSection" className="collapse">
                    <table className="table">
                        <tbody>
                        <tr>
                            <th>{t('project.specialConditions')}</th>
                            <td>
                                <DelayedStringList
                                    initialValue={item.specialConditions}
                                    waitTimeInMs={1000}
                                    onChange={newValue => updateField('specialConditions', newValue)}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#documentsSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="documents"><i className="bi bi-file-earmark-word menuIcon"/>
                    {t('project.documents.title')}
                    <ProjectWarningsBadge project={item} prefix="documents."/>
                </h3>

                <div id="documentsSection" className="collapse">
                    <table className="table">
                        <tbody>
                        {item.documentTypes?.map((documentType, documentTypeIdx) =>
                            <tr key={documentTypeIdx}>
                                <th>{t('project.documents.' + documentType.displayId)}</th>
                                <td>
                                    <ProjectWarnings project={item}
                                                     fieldName={`documents.${documentType.displayId}.documents`}/>
                                    <div>
                                        {documentType.documents?.map((document, documentIdx) =>
                                            <div className="child" key={documentIdx}>
                                                <button className="btn btn-danger float-end" type="button"
                                                        onClick={() => projectDocumentDelete(documentType.displayId, document.id)}>
                                                    X
                                                </button>

                                                <a href={`/project/${id}/documents/${document.id}?fileName=${toDocumentName(t('project.documents.' + documentType.displayId) + '-' + (documentIdx + 1), document.id)}`}
                                                   target="_blank">{toDocumentName("Document-" + (documentIdx + 1), document.id)}</a>
                                                <br/>
                                                <AutoFormField
                                                    fields={traverseFieldDefinition('documentTypes.documents', automaticForm).fields}
                                                    fieldName="number"
                                                    fullFieldName={`documentTypes[${documentTypeIdx}].documents[${documentIdx}].number`}
                                                    labelId="project.document.number"
                                                    project={item}
                                                    parentField={document}
                                                    warningPrefix={`documents.documentTypes[${documentTypeIdx}].documents[${documentIdx}].`}
                                                    listByType={listByType}
                                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItemAcceptEmpty}
                                                />
                                                <AutoFormField
                                                    fields={traverseFieldDefinition('documentTypes.documents', automaticForm).fields}
                                                    fieldName="numberNotApplicable"
                                                    fullFieldName={`documentTypes[${documentTypeIdx}].documents[${documentIdx}].numberNotApplicable`}
                                                    labelId="project.document.numberNotApplicable"
                                                    hideMainLabel={true}
                                                    project={item}
                                                    parentField={document}
                                                    warningPrefix={`documents.documentTypes[${documentTypeIdx}].documents[${documentIdx}].`}
                                                    listByType={listByType}
                                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItemAcceptEmpty}
                                                />
                                                <AutoFormField
                                                    fields={traverseFieldDefinition('documentTypes.documents', automaticForm).fields}
                                                    fieldName="signatureDate"
                                                    fullFieldName={`documentTypes[${documentTypeIdx}].documents[${documentIdx}].signatureDate`}
                                                    labelId="project.document.signatureDate"
                                                    project={item}
                                                    parentField={document}
                                                    warningPrefix={`documents.documentTypes[${documentTypeIdx}].documents[${documentIdx}].`}
                                                    listByType={listByType}
                                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItemAcceptEmpty}
                                                />
                                                <AutoFormField
                                                    fields={traverseFieldDefinition('documentTypes.documents', automaticForm).fields}
                                                    fieldName="receptionDate"
                                                    fullFieldName={`documentTypes[${documentTypeIdx}].documents[${documentIdx}].receptionDate`}
                                                    labelId="project.document.receptionDate"
                                                    project={item}
                                                    parentField={document}
                                                    warningPrefix={`documents.documentTypes[${documentTypeIdx}].documents[${documentIdx}].`}
                                                    listByType={listByType}
                                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItemAcceptEmpty}
                                                />
                                                <AutoFormField
                                                    fields={traverseFieldDefinition('documentTypes.documents', automaticForm).fields}
                                                    fieldName="considerationBeforeCarryingOutInspection"
                                                    fullFieldName={`documentTypes[${documentTypeIdx}].documents[${documentIdx}].considerationBeforeCarryingOutInspection`}
                                                    labelId="project.document.considerationBeforeCarryingOutInspection"
                                                    project={item}
                                                    parentField={document}
                                                    warningPrefix={`documents.documentTypes[${documentTypeIdx}].documents[${documentIdx}].`}
                                                    listByType={listByType}
                                                    onChangeSelectedItem={updateAutomaticOnChangeSelectedItemAcceptEmpty}
                                                />

                                                <div className="clearfix"></div>
                                            </div>
                                        )}
                                    </div>

                                    {(documentType.multi || documentType.documents.length === 0) &&
                                        <FileUpload
                                            name="documents"
                                            accept=".doc, .docx, .pdf"
                                            multi={documentType.multi}
                                            uploadFile={file => projectDocumentAdd(documentType, file)}
                                        />
                                    }

                                    {documentType.documents.length === 0 && <>
                                        <br/>
                                        {t('project.documents.noDocumentJustification')} <br/>
                                        <DelayedInput
                                            type="text" className="form-control"
                                            name="noDocumentJustification"
                                            initialValue={documentType.noDocumentJustification}
                                            waitTimeInMs={1000}
                                            onChange={newValue => projectDocumentUpdateNoDocumentJustification(documentType, newValue)}
                                        />
                                    </>}

                                </td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#allPhotosSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="allPhotos"><i className="bi bi-images menuIcon"/>{t('project.allPhotos')}</h3>

                <div id="allPhotosSection" className="collapse">

                    <FileUpload
                        name="photos"
                        accept=".jpg, .jpeg"
                        multi={true}
                        uploadFile={projectPhotoAdd}
                    />

                    {allPhotosCreateFindingForm &&
                        <FindingCreateEditComponent
                            listByType={listByType}
                            internalListById={internalListById}
                            project={item}
                            initialFinding={allPhotosCreateFindingForm}
                            updated={project => createdFindingWithSeletectedPhotos(project)}
                            cancel={() => setAllPhotosCreateFindingForm(null)}
                        />
                    }

                    {!allPhotosCreateFindingForm &&
                        <PhotoSelector
                            projectId={id}
                            findingId="NEXT"
                            allPhotos={item.photos}
                            selectedIds={[]}
                            showCancel={false}
                            actionLabel="photo.createFindingWithSeletectedPhotos"
                            onAction={createFindingWithSeletectedPhotos}
                            onDelete={deletePhoto}
                        />
                    }

                </div>

                <hr/>

                <button className="btn btn-primary float-end"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#sectionsSection"
                >
                    <i className="bi bi-toggles"></i>
                </button>
                <h3 id="sections"><i className="bi bi-journal-text menuIcon"/>
                    {t('project.sections')}
                    <ProjectWarningsBadge project={item} prefix="sectionIds."/>
                </h3>

                <div id="sectionsSection" className="collapse">

                    <ul>
                        {
                            item.sectionIds && item.sectionIds.map(sectionId => (
                                <li key={sectionId} className="text-primary" onClick={() => scrollToSection(sectionId)}>
                                    {internalListById[sectionId]?.value[toLangOnly(i18n.language)]}
                                </li>
                            ))}
                    </ul>

                    <hr/>

                    {
                        item.sectionIds && item.sectionIds.map(sectionId => (
                            <div className="row" key={sectionId}>
                                <h4 id={`h4-section-${sectionId}`}>
                                    {internalListById[sectionId]?.value[toLangOnly(i18n.language)]}

                                    <button className="btn btn-primary float-end"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={`#section-${sectionId}`}
                                    >
                                        <i className="bi bi-toggles"></i>
                                    </button>

                                    <ProjectWarningsBadge project={item} prefix={`sectionIds.${sectionId}`}/>
                                </h4>

                                <div id={`section-${sectionId}`} className="collapse section">

                                    <ProjectWarnings project={item} fieldName={`sectionIds.${sectionId}`}/>

                                    <button className="btn btn-success float-end"
                                            onClick={() => setShowCreateFindingByKey(prevState => ({
                                                ...prevState,
                                                ['sectionId' + sectionId]: true
                                            }))}
                                    >{t('project.createFinding')}
                                    </button>

                                    {showCreateFindingByKey['sectionId' + sectionId] && <div>
                                        <FindingCreateEditComponent
                                            listByType={listByType}
                                            internalListById={internalListById}
                                            project={item}
                                            updated={project => {
                                                setProject(project)
                                                setShowCreateFindingByKey(prevState => ({
                                                    ...prevState,
                                                    ['sectionId' + sectionId]: false
                                                }))
                                            }}
                                            initialFinding={{
                                                sectionId: sectionId
                                            }}
                                            cancel={() => setShowCreateFindingByKey(prevState => ({
                                                ...prevState,
                                                ['sectionId' + sectionId]: false
                                            }))}
                                        />
                                    </div>
                                    }

                                    <div className="clearfix"></div>

                                    {item.subSectionsBySectionId[sectionId] && item.subSectionsBySectionId[sectionId].map(subSectionId => (
                                        <div key={subSectionId}>
                                            {item.precisionsBySubSectionId[subSectionId].map(precisionId => (
                                                <div className="row" key={precisionId}>
                                                    <h5>
                                                        {internalListById[subSectionId]?.value[toLangOnly(i18n.language)]}
                                                        {precisionId && " - " + internalListById[precisionId]?.value[toLangOnly(i18n.language)]}
                                                    </h5>

                                                    <div>
                                                        <button className="btn btn-success float-end"
                                                                onClick={() => setShowCreateFindingByKey(prevState => ({
                                                                    ...prevState,
                                                                    ['sectionId' + sectionId + 'subSectionId' + subSectionId]: true
                                                                }))}
                                                                title={t('project.createFindingInSubSection')}
                                                        >
                                                            <i className="bi bi-pencil-square"></i>
                                                        </button>
                                                    </div>

                                                    {showCreateFindingByKey['sectionId' + sectionId + 'subSectionId' + subSectionId] &&
                                                        <div>
                                                            <FindingCreateEditComponent
                                                                listByType={listByType}
                                                                internalListById={internalListById}
                                                                project={item}
                                                                updated={project => {
                                                                    setProject(project)
                                                                    setShowCreateFindingByKey(prevState => ({
                                                                        ...prevState,
                                                                        ['sectionId' + sectionId + 'subSectionId' + subSectionId]: false
                                                                    }))
                                                                }}
                                                                initialFinding={{
                                                                    sectionId: sectionId,
                                                                    subSectionId: subSectionId,
                                                                }}
                                                                cancel={() => setShowCreateFindingByKey(prevState => ({
                                                                    ...prevState,
                                                                    ['sectionId' + sectionId + 'subSectionId' + subSectionId]: false
                                                                }))}
                                                            />
                                                        </div>
                                                    }

                                                    <div className="clearfix"></div>

                                                    <div>
                                                        {item.findingsBySubSectionAndPrecisionId[subSectionId + precisionId].map((finding, fIdx) => (
                                                            <div className="row" key={finding.id}>
                                                                <div className="col">
                                                                    <h6>
                                                                        {finding.typeId && internalListById[finding.typeId]?.icon &&
                                                                            <img
                                                                                className="icon"
                                                                                src={`/images/InternalList/${internalListById[finding.typeId]?.icon}`}
                                                                            />}

                                                                        {t('project.finding.title')} - {fIdx + 1}
                                                                    </h6>

                                                                    <button className="btn btn-danger float-end"
                                                                            type="button"
                                                                            onClick={() => deleteFinding(finding.id)}>
                                                                        X
                                                                    </button>
                                                                    <button className="btn btn-primary float-end"
                                                                            type="button"
                                                                            onClick={() => toggleCheckbox(editFindingEditByIds, setEditFindingEditByIds, finding.id)}>
                                                                        <i className="bi bi-pencil-square"></i>
                                                                    </button>

                                                                    {editFindingEditByIds[finding.id] &&
                                                                        <FindingCreateEditComponent
                                                                            listByType={listByType}
                                                                            internalListById={internalListById}
                                                                            project={item}
                                                                            findingId={finding.id}
                                                                            initialFinding={finding}
                                                                            updated={project => findingUpdated(finding.id, project)}
                                                                            cancel={() => toggleCheckbox(editFindingEditByIds, setEditFindingEditByIds, finding.id)}
                                                                        />
                                                                    }

                                                                    {!editFindingEditByIds[finding.id] &&
                                                                        <table className="table">
                                                                            <tbody>
                                                                            <tr>
                                                                                <th>{t('project.finding.observableId')}</th>
                                                                                <td>
                                                                                    {internalListById[finding.observableId]?.value[toLangOnly(i18n.language)]}
                                                                                    <ProjectWarnings project={item}
                                                                                                     fieldName={`sectionIds.${sectionId}.findings.${finding.id}.observableId`}/>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>{t('project.finding.observableInspectedId')}</th>
                                                                                <td>
                                                                                    {internalListById[finding.observableInspectedId]?.value[toLangOnly(i18n.language)]}
                                                                                    <ProjectWarnings project={item}
                                                                                                     fieldName={`sectionIds.${sectionId}.findings.${finding.id}.observableInspectedId`}/>
                                                                                </td>
                                                                            </tr>
                                                                            {finding.observableInspectedId && internalListById[finding.observableInspectedId]?.features.includes('notObservable') &&
                                                                                <>
                                                                                    <tr>
                                                                                        <th>{t('project.finding.notObservableMotiveId')}</th>
                                                                                        <td>
                                                                                            {internalListById[finding.notObservableMotiveId]?.value[toLangOnly(i18n.language)]}
                                                                                            <ProjectWarnings project={item}
                                                                                                             fieldName={`sectionIds.${sectionId}.findings.${finding.id}.notObservableMotiveId`}/> //
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                            {finding.notObservableMotiveId && internalListById[finding.notObservableMotiveId]?.features.includes('other') &&
                                                                                <>
                                                                                    <tr>
                                                                                        <th>{t('project.finding.notObservableMotiveIdOther')}</th>
                                                                                        <td>
                                                                                            {finding.notObservableMotiveIdOther}
                                                                                            <ProjectWarnings project={item}
                                                                                                             fieldName={`sectionIds.${sectionId}.findings.${finding.id}.notObservableMotiveIdOther`}/>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                            {finding.observableInspectedId && !internalListById[finding.observableInspectedId]?.features.includes('notObservable') &&
                                                                                <>
                                                                                    <tr>
                                                                                        <th>{t('project.finding.typeId')}</th>
                                                                                        <td>
                                                                                            {internalListById[finding.typeId]?.value[toLangOnly(i18n.language)]}
                                                                                            <ProjectWarnings project={item}
                                                                                                             fieldName={`sectionIds.${sectionId}.findings.${finding.id}.typeId`}/>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>{t('project.finding.location')}</th>
                                                                                        <td>
                                                                                            {finding.location}
                                                                                            <ProjectWarnings project={item}
                                                                                                             fieldName={`sectionIds.${sectionId}.findings.${finding.id}.location`}/>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                            {finding.observableInspectedId && internalListById[finding.observableInspectedId]?.features.includes('operatingState') &&
                                                                                <>
                                                                                    <tr>
                                                                                        <th>{t('project.finding.operatingStateId')}</th>
                                                                                        <td>
                                                                                            {internalListById[finding.operatingStateId]?.value[toLangOnly(i18n.language)]}
                                                                                            <ProjectWarnings project={item}
                                                                                                             fieldName={`sectionIds.${sectionId}.findings.${finding.id}.operatingStateId`}/>

                                                                                            {finding.operatingStateNotTestedMotiveId && internalListById[finding.operatingStateId]?.features.includes('notTested') &&
                                                                                                <div className="deeper">
                                                                                                    <tr>
                                                                                                        <th>{t('project.finding.operatingStateNotTestedMotiveId')}</th>
                                                                                                        <td>
                                                                                                            {internalListById[finding.operatingStateNotTestedMotiveId]?.value[toLangOnly(i18n.language)]}
                                                                                                            <ProjectWarnings
                                                                                                                project={item}
                                                                                                                fieldName={`sectionIds.${sectionId}.findings.${finding.id}.operatingStateNotTestedMotiveId`}/>

                                                                                                            {finding.operatingStateNotTestedMotiveId && internalListById[finding.operatingStateNotTestedMotiveId]?.features.includes('other') &&
                                                                                                                <div
                                                                                                                    className="deeper">
                                                                                                                    <tr>
                                                                                                                        <th>{t('project.finding.operatingStateNotTestedMotiveOther')}</th>
                                                                                                                        <td>
                                                                                                                            {finding.operatingStateNotTestedMotiveOther}
                                                                                                                            <ProjectWarnings
                                                                                                                                project={item}
                                                                                                                                fieldName={`sectionIds.${sectionId}.findings.${finding.id}.operatingStateNotTestedMotiveOther`}/>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {finding.operatingStateNotTestedMotiveId && internalListById[finding.operatingStateNotTestedMotiveId]?.features.includes('impossibleToStart') &&
                                                                                                                <div
                                                                                                                    className="deeper">
                                                                                                                    <tr>
                                                                                                                        <th>{t('project.finding.operatingStateNotTestedImpossibleStartPrecisionId')}</th>
                                                                                                                        <td>
                                                                                                                            {internalListById[finding.operatingStateNotTestedImpossibleStartPrecisionId]?.value[toLangOnly(i18n.language)]}
                                                                                                                            <ProjectWarnings
                                                                                                                                project={item}
                                                                                                                                fieldName={`sectionIds.${sectionId}.findings.${finding.id}.operatingStateNotTestedImpossibleStartPrecisionId`}/>

                                                                                                                            {finding.operatingStateNotTestedImpossibleStartPrecisionId && internalListById[finding.operatingStateNotTestedImpossibleStartPrecisionId]?.features.includes('other') &&
                                                                                                                                <div
                                                                                                                                    className="deeper">
                                                                                                                                    <tr>
                                                                                                                                        <th>{t('project.finding.operatingStateNotTestedImpossibleStartPrecisionOther')}</th>
                                                                                                                                        <td>
                                                                                                                                            {finding.operatingStateNotTestedImpossibleStartPrecisionOther}
                                                                                                                                            <ProjectWarnings
                                                                                                                                                project={item}
                                                                                                                                                fieldName={`sectionIds.${sectionId}.findings.${finding.id}.operatingStateNotTestedImpossibleStartPrecisionOther`}/>
                                                                                                                                        </td>
                                                                                                                                    </tr>
                                                                                                                                </div>
                                                                                                                            }
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </div>
                                                                                            }

                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                            {finding.observableInspectedId && !internalListById[finding.observableInspectedId]?.features.includes('notObservable') &&
                                                                                <>
                                                                                    <tr>
                                                                                        <th>{t('project.finding.observations')}</th>
                                                                                        <td>
                                                                                            <ul>
                                                                                                {finding.observations.map((observation, oIdx) =>
                                                                                                    <li key={oIdx}>
                                                                                                        <strong>{t('project.finding.observationPrefix')} {oIdx + 1}:</strong> {multilines(observation)}
                                                                                                    </li>
                                                                                                )}
                                                                                            </ul>
                                                                                            <ProjectWarnings project={item}
                                                                                                             fieldName={`sectionIds.${sectionId}.findings.${finding.id}.observations`}/>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>{t('project.finding.comments')}</th>
                                                                                        <td>
                                                                                            <ul>
                                                                                                {finding.comments.map((comment, cIdx) =>
                                                                                                    <li key={cIdx}>
                                                                                                        <strong>{t('project.finding.commentPrefix')} {cIdx + 1}:</strong> {multilines(comment)}
                                                                                                    </li>
                                                                                                )}
                                                                                            </ul>
                                                                                            <ProjectWarnings project={item}
                                                                                                             fieldName={`sectionIds.${sectionId}.findings.${finding.id}.comments`}/>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>{finding.priorityId && internalListById[finding.priorityId]?.icon &&
                                                                                            <img
                                                                                                className="icon"
                                                                                                src={`/images/InternalList/${internalListById[finding.priorityId]?.icon}`}
                                                                                            />}
                                                                                            {t('project.finding.recommendations')}
                                                                                        </th>
                                                                                        <td>
                                                                                            <ul>
                                                                                                {finding.recommendations.map((recommendation, rIdx) =>
                                                                                                    <li key={rIdx}>
                                                                                                        <strong>{t('project.finding.recommendationPrefix')} {rIdx + 1}:</strong> {multilines(recommendation)}
                                                                                                    </li>
                                                                                                )}
                                                                                                {finding.fixedRecommendations.map((recommendation, rIdx) =>
                                                                                                    <li key={rIdx}>
                                                                                                        <strong>{t('project.finding.recommendationPrefix')} {finding.recommendations.length + rIdx + 1}:</strong> {multilines(recommendation)}
                                                                                                    </li>
                                                                                                )}
                                                                                            </ul>
                                                                                            <ProjectWarnings project={item}
                                                                                                             fieldName={`sectionIds.${sectionId}.findings.${finding.id}.recommendations`}/>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <th>{t('project.finding.priorityId')}</th>
                                                                                        <td>
                                                                                            {internalListById[finding.priorityId]?.value[toLangOnly(i18n.language)]}
                                                                                            <ProjectWarnings project={item}
                                                                                                             fieldName={`sectionIds.${sectionId}.findings.${finding.id}.priorityId`}/>
                                                                                        </td>
                                                                                    </tr>
                                                                                </>
                                                                            }
                                                                            <tr>
                                                                                <td colSpan={2}>
                                                                                    <div className="row">
                                                                                        <ProjectWarnings project={item}
                                                                                                         fieldName={`sectionIds.${sectionId}.findings.${finding.id}.photoIds`}/>
                                                                                        {finding.photoIds.map((photoId, photoIdIdx) => (
                                                                                            <div key={photoId}
                                                                                                 className="card col-6 col-sm-6 col-md-6 col-lg-3 col-xl-3 col-xxl-3 bottom-spacing">
                                                                                                <div>
                                                                                                    <img
                                                                                                        src={`/project/${id}/photos/${photoId}?maxSide=1000&findingId=${finding.id}&forceRefresh=${forceRefresh[photoId]}`}
                                                                                                        className="card-img-top"
                                                                                                        onClick={() => changeSelectedPhoto(photoIdIdx, finding)}
                                                                                                    />
                                                                                                </div>
                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}

                                                    </div>
                                                </div>
                                            ))
                                            }
                                        </div>
                                    ))}

                                    <button className="btn btn-secondary float-end me-4"
                                            onClick={scrollToTopSections}>
                                        <i class="bi bi-arrow-up-square"></i>
                                    </button>

                                    <div className="clearfix"></div>

                                    <br/>

                                </div>
                            </div>
                        ))
                    }
                </div>

                <hr/>

            </div>
        </div>
    )
}

export default ProjectViewPage
